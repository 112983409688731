import { useEffect, useMemo, useRef, useState } from 'react'
import Card from '@mui/material/Card'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Button, FormControl, Menu, MenuItem, Radio, RadioGroup, Select } from '@mui/material'
import Map, { Layer, MapRef, Source, ViewState } from 'react-map-gl'
import ConfigProvider from 'balkerne-core/config'
import { useLocation } from '../hooks/locations'
import { useLocationAlerts } from '../hooks/alerts'
import { useSurfaceWater, SurfaceWaterType, useRiverSea, useHistoricalFlood, useProtectedNature } from '../hooks/mapbox'
import { MapLegendWidget } from './widgets/MapLegendWidget'
import bbox from '@turf/bbox'
import { BBox2d } from '@turf/helpers/dist/js/lib/geojson'

const DashboardMap = ({ propertyId }) => {
  const { data: property, isLoading } = useLocation(propertyId, { geojson: true })
  const { data: alerts } = useLocationAlerts(propertyId)
  const [showSurfaceWater, setShowSurfaceWater] = useState(false)
  const [showRiverSea, setShowRiverSea] = useState(false)
  const [showFloods, setShowFloods] = useState(false)
  const [showActiveAreas, setShowActiveAreas] = useState(true)
  const [showProtectedNature, setShowProtectedNature] = useState(false)
  const [showSatellite, setShowSatellite] = useState(false)
  const mapRef = useRef<MapRef | null>(null)

  const {
    Source: WaterSurfaceSource,
    setType: setWaterSurfaceType,
    type: surfaceWaterType,
  } = useSurfaceWater(SurfaceWaterType.EXTENT)
  const { Source: RiverSeaSource } = useRiverSea()
  const { Source: HistoricalFloodSource } = useHistoricalFlood()
  const { Source: ProtectedNatureSource } = useProtectedNature()
  const [element, setElement] = useState(null)
  const menuOpen = Boolean(element)

  const hideFloodLayers = useMemo(() => {
    const country = property?.address?.country ?? null
    if (
      country === 'England' ||
      country === 'Wales' ||
      country === 'Scotland' ||
      country === 'Northern Ireland' ||
      country === 'United Kingdom'
    ) {
      return false
    }
    return true
  }, [property])

  useEffect(() => {
    if (mapRef?.current && property) {
      const map = mapRef.current
      const bounds = bbox(property.geometry) as [number, number, number, number]
      map.fitBounds(bounds, { duration: 2500, padding: 10, maxZoom: 18, pitch: 30 })
    }
  }, [mapRef?.current, property])

  const handleMenuOpen = e => {
    setElement(e.currentTarget)
  }

  const handleMenuClose = () => {
    setElement(null)
  }

  const checkboxSize = 'medium'

  const initialViewState: ViewState & { maxZoom?: number } = {
    longitude: property?.coordinates?.longitude ?? -1.5,
    latitude: property?.coordinates?.latitude ?? 52.5,
    padding: { left: 10, right: 10, top: 10, bottom: 10 },
    maxZoom: 18,
    zoom: 7,
    pitch: 30,
    bearing: 0,
  }
  return (
    <Card sx={{ minHeight: 'fit-content', position: 'relative' }}>
      <Stack gap={2} style={{ marginBottom: 5 }} alignItems="start" direction="row" justifyContent="space-between">
        <Typography variant="subtitle1">Risk Map</Typography>
        <div>
          <Button onClick={handleMenuOpen} variant="outlined">
            Layers
          </Button>
          <Menu open={menuOpen} onClose={handleMenuClose} anchorEl={element}>
            <FormControl>
              <MenuItem>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showActiveAreas}
                      onChange={e => setShowActiveAreas(e.target.checked)}
                      size={checkboxSize}
                    />
                  }
                  label="Active Alerts"
                  sx={{ userSelect: 'none' }}
                />
              </MenuItem>
              <Divider />
              <MenuItem>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showSatellite}
                      onChange={e => setShowSatellite(e.target.checked)}
                      size={checkboxSize}
                    />
                  }
                  label="Satellite"
                  sx={{ userSelect: 'none' }}
                />
              </MenuItem>
              {!hideFloodLayers && (
                <>
                  <Divider />
                  <MenuItem>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showFloods}
                          onChange={e => setShowFloods(e.target.checked)}
                          size={checkboxSize}
                        />
                      }
                      label="Historical Floods"
                      sx={{ userSelect: 'none' }}
                    />
                  </MenuItem>
                  <Divider />
                  <MenuItem>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showRiverSea}
                          onChange={e => setShowRiverSea(e.target.checked)}
                          size={checkboxSize}
                        />
                      }
                      label="River &#038; Sea"
                      style={{ marginTop: 'auto', marginBottom: 'auto' }}
                      sx={{ userSelect: 'none' }}
                    />
                  </MenuItem>
                  <Divider />
                  <MenuItem>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showProtectedNature}
                          onChange={e => setShowProtectedNature(e.target.checked)}
                          size={checkboxSize}
                        />
                      }
                      style={{ marginTop: 'auto', marginBottom: 'auto' }}
                      label="Protected Nature"
                      sx={{ userSelect: 'none' }}
                    />
                  </MenuItem>
                  <Divider />
                  <MenuItem>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showSurfaceWater}
                          onChange={e => setShowSurfaceWater(e.target.checked)}
                          size={checkboxSize}
                        />
                      }
                      style={{ marginTop: 'auto', marginBottom: 'auto' }}
                      label="Surface Water"
                      sx={{ userSelect: 'none' }}
                    />
                  </MenuItem>
                  <RadioGroup value={surfaceWaterType} onChange={e => setWaterSurfaceType(e.target.value)}>
                    <MenuItem>
                      <FormControlLabel value="extent" control={<Radio size="small" />} label="Extent" />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel value="depthHigh" control={<Radio size="small" />} label="Depth | High" />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel value="depthMedium" control={<Radio size="small" />} label="Depth | Medium" />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel value="depthLow" control={<Radio size="small" />} label="Depth | Low" />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel value="velocityHigh" control={<Radio size="small" />} label="Velocity | High" />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel
                        value="velocityMedium"
                        control={<Radio size="small" />}
                        label="Velocity | Medium"
                      />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel value="velocityLow" control={<Radio size="small" />} label="Velocity | Low" />
                    </MenuItem>
                  </RadioGroup>
                </>
              )}
            </FormControl>
          </Menu>
        </div>
      </Stack>
      <Box>
        <Map
          ref={mapRef}
          reuseMaps
          initialViewState={initialViewState}
          style={{ height: '600px', width: '100%', zIndex: 0, borderRadius: 5 }}
          // mapStyle={'mapbox://styles/balkerne/cl9ydd2v700ic15mgaexvvi2j'}
          mapStyle={
            showSatellite ? 'mapbox://styles/mapbox/satellite-v9' : 'mapbox://styles/balkerne/cl9ydd2v700ic15mgaexvvi2j'
          }
          mapboxAccessToken={ConfigProvider.mapboxPublicKey}>
          {showSurfaceWater && <WaterSurfaceSource minZoom={9} />}
          {showRiverSea && <RiverSeaSource minZoom={9}></RiverSeaSource>}
          {showFloods && <HistoricalFloodSource minZoom={12}></HistoricalFloodSource>}
          {showProtectedNature && <ProtectedNatureSource minZoom={12}></ProtectedNatureSource>}

          {alerts.map(alert => (
            <Source
              type="geojson"
              data={alert.area.geometry} // 🚩 Currently one area per alert
            >
              {alert.area.type.point ? (
                <Layer
                  id={`alert-${alert.id}`}
                  type="circle"
                  paint={{
                    'circle-color': '#2f20f7',
                    'circle-opacity': 0.75,
                    'circle-radius': 25,
                  }}
                  layout={{
                    visibility: showActiveAreas ? 'visible' : 'none',
                  }}
                />
              ) : (
                <Layer
                  id={`alert-${alert.id}`}
                  type="fill"
                  paint={{
                    'fill-color': '#2f20f7',
                    'fill-opacity': 0.33,
                  }}
                  layout={{
                    visibility: showActiveAreas ? 'visible' : 'none',
                  }}
                />
              )}
            </Source>
          ))}

          <Source
            type="geojson"
            data={{
              type: 'Feature',
              geometry: property?.geometry,
              properties: {},
            }}>
            <Layer
              id="property"
              type="fill-extrusion"
              paint={{ 'fill-extrusion-color': '#34eb64', 'fill-extrusion-height': 5, 'fill-extrusion-opacity': 0.75 }}
            />
          </Source>
        </Map>
      </Box>
      <MapLegendWidget
        showSurfaceWater={showSurfaceWater}
        showRiverSea={showRiverSea}
        showFloods={showFloods}
        surfaceWaterType={surfaceWaterType}
        overMap
      />
    </Card>
  )
}

export default DashboardMap
