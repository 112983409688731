export const scoreColor = {
  0: '#ffffa6',
  1: '#fbe74d',
  2: '#f19e38',
  3: '#ea3b24',
  4: '#8c1911',
}

export const scoreTextColor = {
  1: 'black',
  2: 'black',
  3: 'white',
  4: 'white',
  5: 'white',
}

export function getRating(score: number): string | null {
  return _scoreToRating?.[score] ?? null
}

const _scoreToRating: { [key: number]: string } = {
  0: 'Low',
  1: 'Low-Medium',
  2: 'Medium-High',
  3: 'High',
  4: 'Extremely High',
}

export default scoreColor
