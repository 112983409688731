export const config = {
  FederatedLoginRedirectURI: 'https://smartresilience.balkerne.com',
  CognitoDomain: 'https://eu-west-1c9stb6thu.auth.eu-west-1.amazoncognito.com/',
  CognitoWebClientId: 'sofp0qa99op7i9fupvsc9q93a', // sofp0qa99op7i9fupvsc9q93a (NEW) 342e6cdv97fsrm2n64o33k1rf0 (OLD)
  CognitoUserPoolId: 'eu-west-1_C9StB6tHu', // eu-west-1_C9StB6tHu (NEW) eu-west-1_Wk48lRToh (OLD)
  CognitoRegion: 'eu-west-1',
  BackendEndpoint: 'https://5j0p0w9ief.execute-api.eu-west-1.amazonaws.com/prod/',
  MitigationsURL: 'https://backend-mitigations-prod.s3.eu-west-1.amazonaws.com/',
  LossIncidentsURL: 'https://backend-loss-incidents-prod.s3.eu-west-1.amazonaws.com/',
  DocumentsURL: 'https://backend-documents-prod.s3.eu-west-1.amazonaws.com/',
}
