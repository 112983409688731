import React, { useEffect, useMemo } from 'react'
import { Box, Stack, Typography, IconButton, Avatar, Hidden, TextField, Chip } from '@mui/material'
import systemActions from '../store/system'
import { useDispatch, useSelector } from 'react-redux'
import { MenuOutlined, NotificationsOutlined } from '@mui/icons-material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { useLocationGroups, useLocations } from '../hooks/locations'
import siteMap from '../siteMap'
import { useHistory } from 'react-router-dom'
import { PortfolioType } from 'balkerne-core/roles'
import { RootState } from '../store'
import { useTheme } from '@mui/material'

const Header = () => {
  const { user } = useSelector((state: RootState) => state.auth)
  const { propertyId } = useSelector((state: RootState) => state.system)
  const { groupId } = useSelector((state: RootState) => state.system)
  const dispatch = useDispatch()
  const history = useHistory()
  const { data: locations, isLoading: isLocationsLoading } = useLocations({ group_id: groupId })
  const { data: groups } = useLocationGroups()
  const handleMenuDrawerToggle = () => dispatch(systemActions.toggleMenuDrawer())
  const handleNotificationDrawerOpen = () => dispatch(systemActions.setNotificationDrawerOpen(true))
  const isPropertyPortfolio = user.role.attributes.portfolio_type === PortfolioType.Property
  const theme = useTheme()

  useEffect(() => {
    if (isPropertyPortfolio && propertyId == null) {
      if (locations.length > 0) {
        const location = locations[0]
        dispatch(systemActions.setPropertyId(location.id))
        history.push(siteMap.Property.getPath(location.id))
      }
    }
  }, [user, locations, propertyId])

  const handleSearchChange = (_, value) => {
    if (value !== null) {
      history.push(siteMap.Property.getPath(value.id))
    } else {
      history.push(siteMap.Portfolio.path)
    }
    dispatch(systemActions.setPropertyId(value?.id))
  }

  const handleGroupChange = (_, value) => {
    dispatch(systemActions.setGroupId(value?.id))
  }

  const selectedLocation = useMemo(
    () => locations.find(location => location?.id === propertyId) ?? null,
    [locations, propertyId],
  )
  const selectedGroup = useMemo(() => groups.find(group => group?.id === groupId) ?? null, [groups, groupId])

  const organisationLogo = user?.organisation.logo

  console.log('groups', groups)

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        height: 60,
        px: 4,
        backgroundColor: 'background.paper',
        borderBottom: '1px solid',
        borderColor: theme.palette.background.borderColor,
      }}>
      {/* Mobile-only Menu Icon */}
      <Hidden smUp>
        <IconButton onClick={handleMenuDrawerToggle}>
          <MenuOutlined />
        </IconButton>
      </Hidden>

      {/* Group Search */}
      <Autocomplete
        id="group-id"
        size="small"
        sx={{ minWidth: 200, width: 200 }}
        autoComplete
        filterOptions={createFilterOptions({ limit: 50 })}
        onChange={handleGroupChange}
        autoHighlight
        autoFocus={false}
        autoSelect={false}
        options={groups}
        value={selectedGroup}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props: any, option) => (
          <Box {...props} key={option.id}>
            <Stack direction="row" spacing={1} flexGrow={1}>
              <Typography variant="body1" sx={{ mr: 1 }}>
                {option.name}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Typography variant="caption">{option.property_customer_reference}</Typography>
            </Stack>
          </Box>
        )}
        getOptionLabel={option => option.name}
        renderInput={params => <TextField {...params} label="Portfolio" placeholder="Name" />}
      />

      {/* Property Search */}
      <Autocomplete
        id="property-id"
        size="small"
        sx={{ width: '100%', maxWidth: 800, display: isPropertyPortfolio ? 'none' : 'block' }}
        autoComplete
        filterOptions={createFilterOptions({ limit: 50 })}
        onChange={handleSearchChange}
        autoHighlight
        autoFocus={false}
        autoSelect={false}
        options={locations}
        value={selectedLocation}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props: any, option) => (
          <Box {...props} key={option.id}>
            <Stack direction="row" spacing={1} flexGrow={1}>
              <Typography variant="body1" sx={{ mr: 1 }}>
                {option.name}
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              {option.groups?.map(group => (
                <Chip label={group.name} size="small" key={group.id} />
              ))}
              <Typography variant="caption">{option.property_customer_reference}</Typography>
            </Stack>
          </Box>
        )}
        getOptionLabel={option => option.name}
        renderInput={params => <TextField {...params} label="Property" placeholder="Name/reference" />}
      />

      <Box sx={{ flexGrow: 1 }} />

      {/* Notification Icon */}
      {/* <IconButton onClick={handleNotificationDrawerOpen}>
        <NotificationsOutlined />
      </IconButton> */}

      {/* Organisation Logo */}
      <Box>
        <img src={organisationLogo} alt="Organisation Logo" height={55} />
      </Box>

      {/* User Profile */}
      <IconButton onClick={() => history.push(siteMap.Settings.path)}>
        <Avatar variant="rounded-s" sx={{ backgroundColor: 'primary.main' }}>
          <Typography color="white" variant="h6">
            {user.full_name?.[0] ?? '?'}
          </Typography>
        </Avatar>
      </IconButton>
    </Stack>
  )
}

export default Header
