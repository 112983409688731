import ConfigProvider from 'balkerne-core/config'
import { addBearerToken, getUser, removeBearerToken } from 'balkerne-core/auth'
import { api } from 'balkerne-core/api'

type TenantInfo = {
  Federation: boolean
  LoginUrl: string
  OrganisationId: string
}

export const getTenantInfo = async (email: string): Promise<TenantInfo | null> => {
  try {
    const response = await api.get('/registration/get-tenant', {
      params: { email },
    })
    return response.data as TenantInfo
  } catch (error) {
    console.error('Error fetching tenant info:', error)
    return null
  }
}

type CognitoTokens = {
  id_token: string
  access_token: string
  refresh_token: string
}

export const storeTokens = (tokens: CognitoTokens) => {
  localStorage.setItem('id_token', tokens.id_token)
  localStorage.setItem('access_token', tokens.access_token)
  localStorage.setItem('refresh_token', tokens.refresh_token)
  addBearerToken(tokens.id_token)
}

export const exchangeCodeForTokens = async (code: string): Promise<CognitoTokens | null> => {
  const redirectUri = ConfigProvider.federatedLoginRedirectURI
  const tokenEndpoint = `${ConfigProvider.cognitoDomain}/oauth2/token`

  const data = new URLSearchParams({
    grant_type: 'authorization_code',
    client_id: ConfigProvider.cognitoWebClientId,
    redirect_uri: redirectUri,
    code,
  })

  try {
    const response = await fetch(tokenEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: data,
    })

    if (!response.ok) {
      throw new Error(`Failed to retrieve tokens: ${response.statusText}`)
    }

    const tokens: CognitoTokens = await response.json()

    return tokens
  } catch (error) {
    console.error('Token exchange error:', error)
    return null
  }
}

export const clearAuthTokens = () => {
  localStorage.removeItem('id_token')
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
}

export const federatedSignOut = async () => {
  clearAuthTokens()
  localStorage.removeItem('federation')
  localStorage.removeItem('user')
  removeBearerToken()
}

export const refreshAuthTokens = async () => {
  const refreshToken = localStorage.getItem('refresh_token')
  if (!refreshToken) {
    clearAuthTokens()
    throw new Error('No refresh token found')
  }

  const tokenEndpoint = `${ConfigProvider.cognitoDomain}/oauth2/token`

  const data = new URLSearchParams({
    grant_type: 'refresh_token',
    client_id: ConfigProvider.cognitoWebClientId,
    refresh_token: refreshToken,
  })

  try {
    const response = await fetch(tokenEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: data,
    })
    if (!response.ok) throw new Error(`Failed to refresh token. Status: ${response.status}`)

    const tokens = await response.json()
    if (!tokens.access_token || !tokens.id_token) throw new Error('Failed to retrieve valid tokens')

    addBearerToken(tokens.id_token)
    localStorage.setItem('id_token', tokens.id_token)
    localStorage.setItem('access_token', tokens.access_token)

    const user = await getUser()
    if (!user) throw new Error("Couldn't fetch user information")

    return { token: tokens, user }
  } catch (error) {
    console.error('Token refresh failed:', error)
    clearAuthTokens()
    throw error
  }
}
