import { useEffect, useMemo, useRef } from 'react'
import { useClimatePortfolioData } from '../../hooks/analytics'
import { Box, Card, FormControl, InputLabel, MenuItem, Select, Stack, Typography, useTheme } from '@mui/material'
import { CardTitle } from './common/Card'
import { useLocations } from '../../hooks/locations'
import LoadingIcon from '../LoadingIcon'
import PortfolioWaterRiskBreakdown from './portfolio/waterRisk/PortfolioWaterRiskBreakdown'
import PortfolioWaterRiskMap from './portfolio/waterRisk/PortfolioWaterRiskMap'

const PortfolioWaterRisk = ({ selectedScenario, setScenarios }) => {
  const { data, isLoading } = useClimatePortfolioData('water-risk')
  const { data: locations, isLoading: isLocationsLoading } = useLocations()
  const theme = useTheme()

  console.log('!!!water-risk', data)

  const availableScenarios = useMemo(() => {
    return Object.keys(data ?? {})
  }, [data])

  useEffect(() => {
    setScenarios(availableScenarios)
  }, [availableScenarios])

  const scenarioData = useMemo(() => {
    return data?.[selectedScenario] ?? null
  }, [data, selectedScenario])

  if (isLoading || isLocationsLoading) {
    return (
      <Stack alignItems="center" justifyContent="center" height="200px">
        <LoadingIcon />
      </Stack>
    )
  }

  if (Object.keys(data ?? {}).length === 0) {
    return (
      <Stack alignItems="center" justifyContent="center" height="200px">
        <Typography variant="h6">No data available for selected hazard</Typography>
      </Stack>
    )
  }

  if (!scenarioData) {
    return (
      <Stack alignItems="center" justifyContent="center" height="200px">
        <Typography variant="h6">No data available for selected scenario</Typography>
      </Stack>
    )
  }

  const gridSpacing = '16px'
  return (
    <Stack alignItems={'center'} pb={6}>
      <Stack sx={{ maxWidth: theme.breakpoints.values?.['lg'], width: '100%' }} direction="column">
        <Box
          sx={{
            display: 'grid',
            gridGap: gridSpacing,
            gridTemplateRows: 'repeat(10, 180px)',
            gridTemplateColumns: 'repeat(8, 1fr)',
            gridTemplateAreas: `"breakdown breakdown breakdown breakdown breakdown breakdown breakdown breakdown"
           "breakdown breakdown breakdown breakdown breakdown breakdown breakdown breakdown"
           "breakdown breakdown breakdown breakdown breakdown breakdown breakdown breakdown"
           "map map map map map map map map"
           "map map map map map map map map"
           "map map map map map map map map"
           `,
            //   gridTemplateAreas: `"chart chart chart chart card1 card1 card2 card2"
            //  "chart chart chart chart card3 card3 card4 card4"
            //  "comp comp comp comp comp dist dist dist"
            //  "comp comp comp comp comp dist dist dist"
            //  "breakdown breakdown breakdown breakdown breakdown breakdown breakdown breakdown"
            //  "breakdown breakdown breakdown breakdown breakdown breakdown breakdown breakdown"
            //  "breakdown breakdown breakdown breakdown breakdown breakdown breakdown breakdown"
            //  "map map map map map map map map"
            //  "map map map map map map map map"
            //  "map map map map map map map map"
            //  `,
          }}>
          {/* --------- */}
          {/* Breakdown */}
          {/* --------- */}
          <PortfolioWaterRiskBreakdown scenario={selectedScenario} />

          {/* --- */}
          {/* Map */}
          {/* --- */}
          <Card sx={{ gridArea: 'map', display: 'flex', flexDirection: 'column' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <CardTitle title="Map" subtitle="Analyse geospatial distribution of risk exposure" />
            </Stack>
            <PortfolioWaterRiskMap scenario={selectedScenario} />
          </Card>
        </Box>
      </Stack>
    </Stack>
  )
}

export default PortfolioWaterRisk
