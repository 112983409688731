export const config = {
  FederatedLoginRedirectURI: 'https://demo.smartresilience.co.uk',
  CognitoDomain: 'https://eu-west-1pzeeuq1n4.auth.eu-west-1.amazoncognito.com/',
  CognitoWebClientId: '3di099q1ep9hmf6nck11mbiddn',
  CognitoUserPoolId: 'eu-west-1_PzeeUq1n4',
  CognitoRegion: 'eu-west-1',
  BackendEndpoint: 'https://j4iht6yw46.execute-api.eu-west-1.amazonaws.com/dev/',
  MitigationsURL: 'https://backend-mitigations-dev.s3.eu-west-1.amazonaws.com/',
  LossIncidentsURL: 'https://backend-loss-incidents-dev.s3.eu-west-1.amazonaws.com/',
  DocumentsURL: 'https://backend-documents-dev.s3.eu-west-1.amazonaws.com/',
}
