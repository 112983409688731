import { useEffect, useMemo, useRef, useState } from 'react'
import { useClimatePortfolioData } from '../../../../hooks/analytics'
import {
  Box,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { Layer, Map, MapRef, Source } from 'react-map-gl'
import ConfigProvider from 'balkerne-core/config'
import { Line, Chart } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartData,
} from 'chart.js'
import { FeatureCollection, Point } from '@turf/turf'
import { GradientLegend } from '../../common/Legend'
import { useLocations } from '../../../../hooks/locations'
import LoadingIcon from '../../../LoadingIcon'
import scoreColor from './utils'
import { CardTitle } from '../../common/Card'
import { DataGrid, GridColumns, GridToolbar } from '@mui/x-data-grid'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import siteMap from '../../../../siteMap'
import { useHistory } from 'react-router-dom'

const PortfolioExtremeWindBreakdown = ({ data, scenario }) => {
  const { groupId } = useSelector((state: RootState) => state.system)
  const { data: locations, isLoading: isLocationsLoading } = useLocations({ group_id: groupId })
  const history = useHistory()

  const dataByPropertyId = useMemo(() => {
    return data?.[scenario] ?? null
  }, [data, scenario])

  console.log('extreme-wind', dataByPropertyId)

  if (isLocationsLoading || !data) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}>
        <LoadingIcon />
      </Box>
    )
  }

  return (
    <Card sx={{ gridArea: 'breakdown' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <CardTitle title="Portfolio Breakdown" subtitle="Properties affected by tropical storm wind speeds" />
      </Stack>
      <Box>
        <DataGrid
          components={{ Toolbar: GridToolbar }}
          sx={{
            height: '470px',
            border: '1px solid transparent',
            '& .MuiDataGrid-footerContainer': {
              border: '1px solid transparent',
            },
          }}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              width: 250,
            },
            {
              field: 'country',
              headerName: 'Country',
              valueGetter: params => {
                return params.row.address.country
              },
              width: 150,
            },
            {
              field: 'insured_amount',
              headerName: 'Insured Amount',
              type: 'number',
              valueGetter: params => {
                const data = dataByPropertyId?.[params.row.id]
                return data?.insured_amount ?? null
              },
              renderCell: params => {
                if (params.value === null) {
                  return 'N/A'
                }
                return Number(params.value).toLocaleString('en-GB', {
                  style: 'currency',
                  currency: 'GBP',
                })
              },
              width: 160,
            },
            {
              field: 'Damages',
              headerName: 'Damages',
              type: 'number',
              valueGetter: params => {
                const data = dataByPropertyId?.[params.row.id]
                console.log(data?.['damages'])
                return data?.['damages'] ?? null
              },
              width: 150,
              renderCell: params => {
                if (params.value === null) {
                  return 'N/A'
                }
                return '£' + params.value
              },
            },
            {
              field: 'percentage_of_insured_value',
              headerName: '% Of Insured Value',
              type: 'number',
              valueGetter: params => {
                const data = dataByPropertyId?.[params.row.id]
                return data?.['%_of_insured_value'] ?? null
              },
              width: 150,
            },
            {
              field: 'mean_wind_speed',
              headerName: 'Avg. Wind Speed',
              type: 'number',
              valueGetter: params => {
                const data = dataByPropertyId?.[params.row.id]
                return data?.['Mean Wind Speed'] ?? null
              },
              renderCell: params => {
                if (params.value === null) {
                  return 'N/A'
                }
                const number = Number(params.value).toFixed(1)
                return number + ' km/h'
              },
              width: 150,
            },
            {
              field: 'landfall_count',
              headerName: '# Storm Landfalls',
              type: 'number',
              valueGetter: params => {
                const data = dataByPropertyId?.[params.row.id]
                return data?.['landfall_count_present_1.5'] ?? null
              },
              renderCell: params => {
                if (params.value === null) {
                  return 'N/A'
                }
                return params.value
              },
              width: 150,
            },
            // {
            //   field: 'damages_future',
            //   headerName: 'Future Damages',
            //   valueGetter: params => {
            //     const data = dataByPropertyId?.[params.row.id]
            //     return data?.['damages_future'] ?? 'N/A'
            //   },
            //   width: 150,
            //   renderCell: params => {
            //     if (params.value === 'N/A') {
            //       return 'N/A'
            //     }
            //     return '£' + params.value
            //   },
            // },
            // {
            //   field: 'percentage_of_insured_value_future',
            //   headerName: '% Of Insured Value Future',
            //   valueGetter: params => {
            //     const data = dataByPropertyId?.[params.row.id]
            //     return data?.['%_of_insured_value_future'] ?? 'N/A'
            //   },
            //   width: 150,
            // },
            // {
            //   field: 'damages_pct_change',
            //   headerName: 'Damages % Change',
            //   valueGetter: params => {
            //     const data = dataByPropertyId?.[params.row.id]
            //     return data?.['damages_pct_change'] ?? 'N/A'
            //   },
            //   width: 150,
            //   renderCell: params => {
            //     if (params.value === 'N/A') {
            //       return 'N/A'
            //     }
            //     return params.value + '%'
            //   },
            // },
          ]}
          pageSize={10}
          density="compact"
          getRowId={(row: any) => row.id}
          rows={locations ?? []}
          onRowDoubleClick={row => history.push(siteMap.Property.getPath(row.id))}
        />
      </Box>
    </Card>
  )
}

export default PortfolioExtremeWindBreakdown
