import { useEffect, useMemo, useRef } from 'react'
import { useClimatePortfolioData } from '../../../../hooks/analytics'
import { Box, Card, FormControl, InputLabel, MenuItem, Select, Stack, Typography, useTheme } from '@mui/material'
import { CardTitle } from './../../common/Card'
import { useLocations } from '../../../../hooks/locations'
import LoadingIcon from '../../../LoadingIcon'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { scoreTextColor, scoreColor, getRating } from './utils'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { useHistory } from 'react-router-dom'
import siteMap from '../../../../siteMap'

const dataCols = [
  {
    field: 'near-term',
    name: 'Near Term (2030)',
    indexKey: 0,
  },
  {
    field: 'mid-term',
    name: 'Mid Term (2050)',
    indexKey: 1,
  },
  {
    field: 'long-term',
    name: 'Long Term (2090)',
    indexKey: 2,
  },
]

const PortfolioWaterRiskBreakdown = ({ scenario }) => {
  const { groupId } = useSelector((state: RootState) => state.system)
  const { data, isLoading } = useClimatePortfolioData('water-risk')
  const { data: locations, isLoading: isLocationsLoading } = useLocations({ group_id: groupId })
  const history = useHistory()

  const dataById = useMemo(() => {
    return data?.[scenario] ?? null
  }, [data, scenario])

  if (isLoading || isLocationsLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}>
        <LoadingIcon />
      </Box>
    )
  }

  console.log('water-risk', data)

  return (
    <Card sx={{ gridArea: 'breakdown' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <CardTitle title="Portfolio Breakdown" subtitle="Property water stress for different periods" />
      </Stack>
      <Box>
        <DataGrid
          components={{ Toolbar: GridToolbar }}
          sx={{
            height: '470px',
            border: '1px solid transparent',
            '& .MuiDataGrid-footerContainer': {
              border: '1px solid transparent',
            },
          }}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              width: 250,
            },
            {
              field: 'country',
              headerName: 'Country',
              valueGetter: params => {
                return params.row.address.country
              },
              width: 150,
            },
            ...dataCols.map(col => {
              return {
                field: col.field,
                headerName: col.name,
                valueGetter: params => {
                  const value = dataById?.[params.row.id]?.['data'][col.indexKey]['indicators']['ws']
                  return value?.['category'] ?? null
                },
                renderCell: params => {
                  const color = params.value in scoreColor ? scoreColor[params.value] : 'transparent'
                  const textColor = params.value in scoreTextColor ? scoreTextColor[params.value] : 'black'
                  const text = getRating(params.value) ?? '-'
                  return (
                    <Stack
                      justifyContent="center"
                      sx={{
                        backgroundColor: color,
                        color: textColor,
                        borderRadius: '4px',
                        px: '8px',
                        py: '2px',
                      }}>
                      <Typography variant="body2">{text}</Typography>
                    </Stack>
                  )
                },
                width: 160,
              }
            }),
          ]}
          pageSize={10}
          density="compact"
          getRowId={(row: any) => row.id}
          onRowDoubleClick={params => {
            history.push(siteMap.Property.getPath(params.row.id))
          }}
          rows={locations ?? []}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'long-term',
                  sort: 'desc',
                },
              ],
            },
          }}
        />
      </Box>
    </Card>
  )
}

export default PortfolioWaterRiskBreakdown
