import { Bar } from 'react-chartjs-2'
import { SCENARIO_TYPE } from '../../pages/TransitionRisks'
import { Box, Card, Grid } from '@mui/material'
import { useMemo, useState } from 'react'
import { customXScale, exportCSV, generateUniqueLegendLabels, TransitionRiskAsset, useCarbonProfile } from './helpers'
import LoadingIcon from '../LoadingIcon'
import { ChartHeader } from './ChartHeader'

const chart2Colours = {
  'fleet 2017': '#F07300',
  'asset 2017': '#FBD0A8',
  'fleet 2024': '#027399',
  'asset 2024': '#91E3FF',
}

const EmissionsProfile = ({ scenario }: { scenario: SCENARIO_TYPE }) => {
  const { carbonProfile, isLoading } = useCarbonProfile()

  const chartData = useMemo(() => {
    const chart1Datasets = {
      '2017_Scope_1_Emissions_Intensity': {
        label: 'Scope 1 2017',
        data: [],
        backgroundColor: '#FBD0A8',
        stack: '2017',
      },
      '2017_Scope_2_Emissions_Intensity': {
        label: 'Scope 2 2017',
        data: [],
        backgroundColor: '#F07300',
        stack: '2017',
      },
      '2024_Scope_1_Emissions_Intensity': {
        label: 'Scope 1 2024',
        data: [],
        backgroundColor: '#91E3FF',
        stack: '2024',
      },
      '2024_Scope_2_Emissions_Intensity': {
        label: 'Scope 2 2024',
        data: [],
        backgroundColor: '#027399',
        stack: '2024',
      },
    }

    const totalEmissionsChart: Record<string, any> = {}
    const emissionsIntensityChart: Record<string, any> = {}

    const initializeBusinessRegion = (businessRegion: string, data: Record<string, any>) => {
      if (!data[businessRegion]) {
        data[businessRegion] = {
          '2017_Scope_1': 0,
          '2017_Scope_2': 0,
          '2017_Total_Revenue': 0,
          '2024_Scope_1': 0,
          '2024_Scope_2': 0,
          '2024_Total_Revenue': 0,
          itemClasses: {},
          '2017_Scope_1_Emissions_Intensity': 0,
          '2017_Scope_2_Emissions_Intensity': 0,
          '2024_Scope_1_Emissions_Intensity': 0,
          '2024_Scope_2_Emissions_Intensity': 0,
        }
      }
    }

    const initializeItemClass = (businessRegion: string, itemClass: string, data: Record<string, any>) => {
      if (!data[businessRegion]['itemClasses'][itemClass]) {
        data[businessRegion]['itemClasses'][itemClass] = {
          '2017_Total': 0,
          '2024_Total': 0,
        }
      }
    }
    carbonProfile.forEach(asset => {
      const businessRegion = asset.businessRegion
      if (!businessRegion) {
        return
      }
      const itemClass = asset.itemClass
      if (!itemClass) {
        return
      }
      initializeBusinessRegion(businessRegion, totalEmissionsChart)
      initializeItemClass(businessRegion, itemClass, totalEmissionsChart)
      totalEmissionsChart[businessRegion]['itemClasses'][itemClass]['2017_Total'] += asset.totalEmissions2017 || 0
      totalEmissionsChart[businessRegion]['itemClasses'][itemClass]['2024_Total'] += asset.totalEmissions2024 || 0

      if (asset.revenue2017) {
        initializeBusinessRegion(businessRegion, emissionsIntensityChart)
        emissionsIntensityChart[businessRegion]['2017_Scope_1'] += (asset.scope1Emissions2017 || 0) * 1000
        emissionsIntensityChart[businessRegion]['2017_Scope_2'] += (asset.scope2Emissions2017 || 0) * 1000
        emissionsIntensityChart[businessRegion]['2017_Total_Revenue'] += asset.revenue2017
      }
      if (asset.revenue2024) {
        initializeBusinessRegion(businessRegion, emissionsIntensityChart)
        emissionsIntensityChart[businessRegion]['2024_Scope_1'] += (asset.scope1Emissions2024 || 0) * 1000
        emissionsIntensityChart[businessRegion]['2024_Scope_2'] += (asset.scope2Emissions2024 || 0) * 1000
        emissionsIntensityChart[businessRegion]['2024_Total_Revenue'] += asset.revenue2024
      }
    })
    Object.keys(emissionsIntensityChart).forEach(businessRegion => {
      emissionsIntensityChart[businessRegion]['2017_Scope_1_Emissions_Intensity'] =
        emissionsIntensityChart[businessRegion]['2017_Scope_1'] /
        emissionsIntensityChart[businessRegion]['2017_Total_Revenue']
      emissionsIntensityChart[businessRegion]['2017_Scope_2_Emissions_Intensity'] =
        emissionsIntensityChart[businessRegion]['2017_Scope_2'] /
        emissionsIntensityChart[businessRegion]['2017_Total_Revenue']
      emissionsIntensityChart[businessRegion]['2024_Scope_1_Emissions_Intensity'] =
        emissionsIntensityChart[businessRegion]['2024_Scope_1'] /
        emissionsIntensityChart[businessRegion]['2024_Total_Revenue']
      emissionsIntensityChart[businessRegion]['2024_Scope_2_Emissions_Intensity'] =
        emissionsIntensityChart[businessRegion]['2024_Scope_2'] /
        emissionsIntensityChart[businessRegion]['2024_Total_Revenue']
    })

    const chart2Labels = Object.keys(totalEmissionsChart)
    const chart2ItemClasses = Array.from(
      new Set(chart2Labels.flatMap(businessRegion => Object.keys(totalEmissionsChart[businessRegion]['itemClasses']))),
    )
    const chart2Datasets: any[] = []
    chart2ItemClasses.forEach(itemClass => {
      chart2Datasets.push({
        label: `${itemClass} 2017`,
        data: chart2Labels.map(
          businessRegion => totalEmissionsChart[businessRegion]['itemClasses'][itemClass]?.['2017_Total'] || 0,
        ),
        backgroundColor: chart2Colours[`${itemClass} 2017`] || '#CCCCCC',
        stack: '2017',
      })
    })
    chart2ItemClasses.forEach(itemClass => {
      chart2Datasets.push({
        label: `${itemClass} 2024`,
        data: chart2Labels.map(
          businessRegion => totalEmissionsChart[businessRegion]['itemClasses'][itemClass]?.['2024_Total'] || 0,
        ),
        backgroundColor: chart2Colours[`${itemClass} 2024`] || '#CCCCCC',
        stack: '2024',
      })
    })
    return {
      chart1: {
        labels: Object.keys(emissionsIntensityChart),
        datasets: Object.keys(chart1Datasets).map(key => ({
          ...chart1Datasets[key],
          data: Object.keys(emissionsIntensityChart).map(
            businessRegion => emissionsIntensityChart[businessRegion][key],
          ),
        })),
      },
      chart2: {
        labels: chart2Labels,
        datasets: chart2Datasets,
      },
    }
  }, [carbonProfile])

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <LoadingIcon />
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <Bar
                options={{
                  interaction: {
                    mode: 'nearest',
                    axis: 'x',
                    intersect: false,
                  },
                  plugins: {
                    title: {
                      display: true,
                      text: 'Total Current Emissions by Region (tCO2e)',
                      align: 'start',
                      color: '#53559D',
                      font: {
                        size: 16,
                        weight: 'bold',
                      },
                    },
                    tooltip: {
                      filter: tooltipItem => true,
                    },
                    legend: {
                      position: 'top',
                      align: 'end',
                      labels: {
                        generateLabels: generateUniqueLegendLabels,
                      },
                      onClick: (event, legendItem, legend) => {
                        const chart = legend.chart
                        const categoryName = legendItem.text.trim()

                        const matchingIndexes = chart.data.datasets
                          .map((dataset, index) => ((dataset.label?.trim() || '') === categoryName ? index : -1))
                          .filter(index => index !== -1)

                        if (matchingIndexes.length === 0) return

                        const allVisible = matchingIndexes.every(index => !chart.getDatasetMeta(index).hidden)

                        matchingIndexes.forEach(index => {
                          chart.getDatasetMeta(index).hidden = allVisible
                        })

                        chart.update()
                      },
                    },
                  },
                  responsive: true,
                  layout: {
                    padding: {
                      bottom: 40,
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        drawOnChartArea: false,
                      },
                      ticks: {
                        display: false,
                      },
                    },
                    y: {
                      ticks: {
                        display: true,
                      },
                      stacked: true,
                    },
                  },
                }}
                plugins={[customXScale]}
                data={chartData.chart2}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              {chartData?.chart1 && (
                <Bar
                  options={{
                    interaction: {
                      mode: 'nearest',
                      axis: 'x',
                      intersect: false,
                    },
                    plugins: {
                      title: {
                        display: true,
                        text: 'Emissions Intensity kgCO2e/£',
                        align: 'start',
                        color: '#53559D',
                        font: {
                          size: 16,
                          weight: 'bold',
                        },
                      },
                      tooltip: {
                        filter: tooltipItem => true,
                      },
                      legend: {
                        position: 'top',
                        align: 'end',
                        labels: {
                          generateLabels: generateUniqueLegendLabels,
                        },
                        onClick: (event, legendItem, legend) => {
                          const chart = legend.chart
                          const categoryName = legendItem.text.trim()

                          const matchingIndexes = chart.data.datasets
                            .map((dataset, index) => ((dataset.label?.trim() || '') === categoryName ? index : -1))
                            .filter(index => index !== -1)

                          if (matchingIndexes.length === 0) return

                          const allVisible = matchingIndexes.every(index => !chart.getDatasetMeta(index).hidden)

                          matchingIndexes.forEach(index => {
                            chart.getDatasetMeta(index).hidden = allVisible
                          })

                          chart.update()
                        },
                      },
                    },
                    layout: {
                      padding: {
                        bottom: 40,
                      },
                    },
                    scales: {
                      x: {
                        grid: {
                          drawOnChartArea: false,
                        },
                        ticks: {
                          display: false,
                        },
                      },
                      y: {
                        ticks: {
                          display: true,
                        },
                        stacked: true,
                      },
                    },
                    responsive: true,
                  }}
                  plugins={[customXScale]}
                  data={chartData.chart1}
                />
              )}
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default EmissionsProfile
