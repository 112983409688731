import { Box, Card, Grid, Typography } from '@mui/material'
import { SCENARIO_TYPE } from '../../pages/TransitionRisks'
import { Bar, Line } from 'react-chartjs-2'
import { useMemo } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { customXScale, generateUniqueLegendLabels, useEnergyCostChange, useEnergyProfile } from './helpers'
import LoadingIcon from '../LoadingIcon'

const energyTypes = [
  'energyConsumptionCoal',
  'energyConsumptionGas',
  'energyConsumptionOil',
  'energyConsumptionOther',
  'energyConsumptionElectricityRenewables',
  'energyConsumptionPurchasedRenewables',
  'energyConsumptionElectricityNonRenewables',
]
const getEnergyColor = type => {
  const colors = {
    energyConsumptionCoal: '#91E3FF',
    energyConsumptionGas: '#6B6DA7',
    energyConsumptionOil: '#0285B1',
    energyConsumptionOther: '#E1E1ED',
    energyConsumptionElectricityRenewables: '#F66D02',
    energyConsumptionPurchasedRenewables: '#9FC818',
    energyConsumptionElectricityNonRenewables: '#FBD0A8',
  }
  return colors[type] || '#000000'
}

const EnergyCost = ({ scenario }: { scenario: SCENARIO_TYPE }) => {
  const { energyCostChange, isLoading: costLoading } = useEnergyCostChange()
  const { energyProfile, isLoading: profileLoading } = useEnergyProfile()

  const chartData = useMemo(() => {
    const { data, businessRegions } = energyProfile
    const energyMixChartData = {
      labels: businessRegions,
      datasets: [
        ...Array.from(energyTypes).map((type: string) => ({
          label: `${type.replace('energyConsumption', '')}`,
          data: businessRegions.map(businessRegion =>
            data[2017]
              .filter(asset => asset.businessRegion === businessRegion)
              .reduce((sum, asset: any) => sum + (asset[type] || 0), 0),
          ),
          backgroundColor: getEnergyColor(type),
          stack: '2017',
        })),

        ...Array.from(energyTypes).map((type: string) => ({
          label: `${type.replace('energyConsumption', '')}`,
          data: businessRegions.map(businessRegion =>
            data[2024]
              .filter((asset: any) => asset.businessRegion === businessRegion)
              .reduce((sum, asset: any) => sum + (asset[type] || 0), 0),
          ),
          backgroundColor: getEnergyColor(type),
          stack: '2024',
        })),
      ],
    }

    const scenarios: string[] = Object.keys(energyCostChange)
    const getScenarioColor = type => {
      const colors = {
        [SCENARIO_TYPE.NATIONAL_DETERMINED_CONTRIBUTION]: '#F4B300',
        [SCENARIO_TYPE.FRAGMENTED_WORLD]: '#F66D02',
        [SCENARIO_TYPE.CURRENT_POLICIES]: '#0285B1',
        [SCENARIO_TYPE.BELOW_2_CELSIUS]: '#C81885',
        [SCENARIO_TYPE.NET_ZERO_2050]: '#4475C0',
        [SCENARIO_TYPE.LOW_DEMAND]: '#9FC818',
        [SCENARIO_TYPE.DELAYED_TRANSITION]: '#C31919',
      }
      return colors[type] || 'rgba(0, 0, 0, 1)'
    }
    const fixScenarioLabel = (scenario: string) => {
      switch (scenario) {
        case SCENARIO_TYPE.DELAYED_TRANSITION:
          return 'Delayed Transition'
        case SCENARIO_TYPE.LOW_DEMAND:
          return 'Low Demand'
        case SCENARIO_TYPE.BELOW_2_CELSIUS:
          return 'Below 2°C'
        default:
          return scenario
      }
    }
    const years = [2024, 2025, 2026, 2027, 2028, 2029, 2030]
    const scenarioCostChangeChartData = {
      labels: years,
      datasets: scenarios.map((scenario: any) => {
        const dataPoints = years.map(year => {
          return energyCostChange[scenario]?.[year].currentConsumption
        })
        return {
          label: fixScenarioLabel(scenario),
          data: dataPoints,
          borderColor: getScenarioColor(scenario),
          fill: false,
          tension: 0.2,
        }
      }),
    }

    const dataPoints = years.map(year => {
      const currentConsumption = energyCostChange[scenario]?.[year].currentConsumption || 0
      const targetConsumptionModelScenario = energyCostChange[scenario]?.[year].targetConsumptionModelScenario || 0
      const targetConsumption2024Price = energyCostChange[scenario]?.[year].targetConsumption2024Price || 0

      return {
        year,
        currentConsumption,
        targetConsumptionModelScenario,
        targetConsumption2024Price,
      }
    })
    const currentVsTargetEmissionsChartData = {
      labels: years,
      datasets: [
        {
          label: 'Current Consumption',
          data: dataPoints.map(dp => dp.currentConsumption),
          borderColor: '#0285B1',
          fill: false,
          tension: 0.2,
        },
        {
          label: 'Target Consumption (at scenario modeled costs)',
          data: dataPoints.map(dp => dp.targetConsumptionModelScenario),
          borderColor: '#C81885',
          fill: false,
          tension: 0.2,
        },
        {
          label: 'Target Consumption (at current costs)',
          data: dataPoints.map(dp => dp.targetConsumption2024Price),
          borderColor: '#F66D02',
          fill: false,
          tension: 0.2,
        },
      ],
    }
    const energyTableData = data[2024].map(asset => {
      return {
        id: asset.name,
        asset_name: asset.name,
        continent: asset.continent || '',
        sector: asset.sector || '',
        consumption: asset.energyConsumptionTotal || 0,
      }
    })
    return {
      energyMixChartData,
      scenarioCostChangeChartData,
      currentVsTargetEmissionsChartData,
      energyTableData,
    }
  }, [scenario, energyCostChange, energyProfile])
  return (
    <>
      {profileLoading || costLoading ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <LoadingIcon />
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <Bar
                options={{
                  interaction: {
                    mode: 'nearest',
                    axis: 'x',
                    intersect: false,
                  },
                  plugins: {
                    tooltip: {
                      filter: tooltipItem => true,
                    },
                    title: {
                      display: true,
                      text: 'Current Energy Consumption & Mix (kwh)',
                      align: 'start',
                      color: '#53559D',
                      font: {
                        size: 16,
                        weight: 'bold',
                      },
                    },
                    legend: {
                      position: 'top',
                      align: 'end',
                      labels: {
                        generateLabels: generateUniqueLegendLabels,
                      },
                      onClick: (event, legendItem, legend) => {
                        const chart = legend.chart
                        const categoryName = legendItem.text.trim()

                        const matchingIndexes = chart.data.datasets
                          .map((dataset, index) => ((dataset.label?.trim() || '') === categoryName ? index : -1))
                          .filter(index => index !== -1)

                        if (matchingIndexes.length === 0) return

                        const allVisible = matchingIndexes.every(index => !chart.getDatasetMeta(index).hidden)

                        matchingIndexes.forEach(index => {
                          chart.getDatasetMeta(index).hidden = allVisible
                        })

                        chart.update()
                      },
                    },
                  },
                  layout: {
                    padding: {
                      bottom: 40,
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        drawOnChartArea: false,
                      },
                      ticks: {
                        display: false,
                      },
                    },
                    y: {
                      ticks: {
                        display: true,
                      },
                    },
                  },
                  responsive: true,
                }}
                data={chartData.energyMixChartData}
                plugins={[customXScale]}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <Line
                options={{
                  plugins: {
                    filler: {
                      propagate: true,
                    },
                    title: {
                      display: true,
                      text: 'Relative Energy Cost Change (vs baseline)',
                      align: 'start',
                      color: '#53559D',
                      font: {
                        size: 16,
                        weight: 'bold',
                      },
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        drawOnChartArea: false,
                      },
                    },
                  },
                  responsive: true,
                }}
                data={chartData.currentVsTargetEmissionsChartData}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <Line
                options={{
                  interaction: {
                    mode: 'point',
                    axis: 'x',
                    intersect: false,
                  },
                  plugins: {
                    filler: {
                      propagate: true,
                    },
                    title: {
                      display: true,
                      text: 'Relative Energy Cost Change by Scenario (based on current consumption and mix)',
                      align: 'start',
                      color: '#53559D',
                      font: {
                        size: 16,
                        weight: 'bold',
                      },
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        drawOnChartArea: false,
                      },
                    },
                  },
                  responsive: true,
                }}
                data={chartData.scenarioCostChangeChartData}
              />
              <Typography variant="subtitle2" color="textPrimary" sx={{ padding: 2 }}>
                Current Policies, Delayed Transition and Fragmented World overlap until 2030
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={chartData.energyTableData}
                  columns={[
                    { field: 'asset_name', headerName: 'Asset Name', width: 300 },
                    { field: 'continent', headerName: 'Region', width: 150 },
                    { field: 'sector', headerName: 'Category', width: 150 },
                    {
                      field: 'consumption',
                      headerName: 'Consumption (kwh)',
                      width: 200,
                      valueFormatter: params => {
                        if (params.value === null) return ''
                        return params.value.toLocaleString('en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })
                      },
                    },
                  ]}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default EnergyCost
