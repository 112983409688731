import { Box, Card, Grid, Typography } from '@mui/material'
import { Bubble } from 'react-chartjs-2'
import { DataGrid } from '@mui/x-data-grid'
import { useMemo, useState } from 'react'
import { useSupplierEmissionsProfile } from './helpers'
import LoadingIcon from '../LoadingIcon'
import { TooltipItem } from 'chart.js'

const scaleRadius = (spend: number, maxSpend: number, minRadius = 1, maxRadius = 18) => {
  if (maxSpend === 0) return minRadius
  const normalized = Math.sqrt(spend / maxSpend)

  return minRadius + normalized * (maxRadius - minRadius)
}

const SupplierEmissionsProfile = () => {
  const { supplierProfile, isLoading } = useSupplierEmissionsProfile()
  const [labels, setLabels] = useState<{ x: string[]; y: string[] }>({ x: [''], y: [''] })
  const [maxSpend, setMaxSpend] = useState(0)

  const chartData = useMemo(() => {
    const { profile } = supplierProfile
    const result: Record<string, any> = {}

    const yLabels: string[] = ['']
    const xLabels: string[] = ['']

    profile.forEach(supplier => {
      const sector = supplier.sector
      const spendGBP = supplier.spendGBP || 0
      const emissions = supplier.carbonProfile?.[2024]?.totalEmissions
      const continent = supplier.continent
      if (!continent) return
      if (!result[continent]) {
        result[continent] = {}
      }
      if (!result[continent][sector]) {
        result[continent][sector] = {
          spendGBP: 0,
          emissions: 0,
          emissionsIntensity: 0,
        }
      }

      result[continent][sector].spendGBP += spendGBP
      result[continent][sector].emissions += emissions
    })
    const _maxSpend = Math.max(
      ...Object.keys(result).flatMap(continent =>
        Object.keys(result[continent]).map(sector => result[continent][sector]['spendGBP']),
      ),
    )
    setMaxSpend(_maxSpend)
    Object.keys(result).forEach(continent => {
      if (!xLabels.includes(continent)) xLabels.push(continent)
      Object.keys(result[continent]).forEach(sector => {
        if (!yLabels.includes(sector)) yLabels.push(sector)
        result[continent][sector]['emissionsIntensity'] =
          (result[continent][sector]['emissions'] * 1000) / result[continent][sector]['spendGBP']
      })
    })
    setLabels({ x: xLabels, y: yLabels })

    const minIntensity = Math.min(
      ...Object.keys(result).flatMap(continent =>
        Object.keys(result[continent]).map(sector => result[continent][sector]['emissionsIntensity']),
      ),
    )
    const maxIntensity = Math.max(
      ...Object.keys(result).flatMap(continent =>
        Object.keys(result[continent]).map(sector => result[continent][sector]['emissionsIntensity']),
      ),
    )
    const SCALE_FACTOR = 1 / (maxIntensity - minIntensity)

    const getGradientColor = (normalizedIntensity: number) => {
      const minRGB = [3, 192, 255]
      const maxRGB = [2, 115, 153]
      let intensity = Math.min(1, Math.max(0, normalizedIntensity * SCALE_FACTOR))

      const r = Math.round((1 - intensity) * minRGB[0] + intensity * maxRGB[0])
      const g = Math.round((1 - intensity) * minRGB[1] + intensity * maxRGB[1])
      const b = Math.round((1 - intensity) * minRGB[2] + intensity * maxRGB[2])

      return `rgb(${r}, ${g}, ${b})`
    }

    const datasets: any = []
    Object.keys(result).forEach(continent => {
      Object.keys(result[continent]).forEach(sector => {
        const emissionsIntensity = result[continent][sector]['emissionsIntensity']
        const spendGBP = result[continent][sector]['spendGBP']
        datasets.push({
          x: xLabels.indexOf(continent),
          y: yLabels.indexOf(sector),
          _custom: {
            emissionsIntensity: emissionsIntensity,
            spendGBP,
          },
        })
      })
    })
    const supplierBenchmark = {
      datasets: [
        {
          label: 'Spend',
          data: datasets,
          pointBackgroundColor: (ctx: any) => {
            const emissionsIntensity = ctx.raw._custom.emissionsIntensity
            const normalizedIntensity = (emissionsIntensity - minIntensity) / (maxIntensity - minIntensity)
            return getGradientColor(normalizedIntensity)
          },
          pointBorderColor: (ctx: any) => {
            const emissionsIntensity = ctx.raw._custom.emissionsIntensity
            const normalizedIntensity = (emissionsIntensity - minIntensity) / (maxIntensity - minIntensity)
            return getGradientColor(normalizedIntensity)
          },
          pointRadius: (ctx: any) => {
            const spendGBP = ctx.raw._custom.spendGBP
            const bubbleRadius = scaleRadius(spendGBP, _maxSpend)
            return bubbleRadius
          },
        },
      ],
    }

    const supplierDetailsTable = profile.map((supplier, index) => {
      const supplierEmissions = supplier.carbonProfile?.[2024].totalEmissions || 0
      const supplierEmissionsIntensity = (supplierEmissions * 1000) / supplier.spendGBP
      return {
        id: `${index}-${supplier.name}`,
        name: supplier.name,
        region: supplier.continent,
        category: supplier.sector,
        spend: supplier.spendGBP,
        emissions: supplierEmissions,
        emissionsIntensity: supplierEmissionsIntensity,
      }
    })
    return {
      supplierBenchmark,
      supplierDetailsTable,
    }
  }, [supplierProfile])

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <LoadingIcon />
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2 }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 'bold',
                    color: '#53559D',
                  }}>
                  Supplier Emissions
                </Typography>
                <CustomBubbleLegend maxSpend={maxSpend} />
              </Box>
              <Bubble
                options={{
                  plugins: {
                    tooltip: {
                      callbacks: {
                        title: tooltipItems => {
                          return `${labels.x[tooltipItems[0].parsed.x]}`
                        },
                        label: (tooltipItem: TooltipItem<'bubble'>) => {
                          const dataPoint = tooltipItem.raw as {
                            _custom: { emissionsIntensity: number; spendGBP: number }
                          }

                          const sector = labels.y[tooltipItem.parsed.y]
                          const spend = dataPoint._custom.spendGBP
                          const emissions = dataPoint._custom.emissionsIntensity

                          return [
                            `${sector}`,
                            `Spend: £${spend.toLocaleString('en-US', {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}`,
                            `Emissions Intensity (kgCO2e/£): ${emissions.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}`,
                          ]
                        },
                      },
                    },
                    legend: {
                      display: false,
                    },
                  },
                  responsive: true,
                  scales: {
                    y: {
                      offset: true,
                      ticks: {
                        callback: (value, index, ticks) => {
                          return labels.y[value]
                        },
                        stepSize: 1,
                      },
                    },
                    x: {
                      offset: true,
                      grid: {
                        drawOnChartArea: false,
                      },
                      ticks: {
                        callback: (value, index, ticks) => {
                          return labels.x[value]
                        },
                        padding: 10,
                      },
                    },
                  },
                }}
                data={chartData.supplierBenchmark}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={chartData.supplierDetailsTable}
                  columns={[
                    { field: 'name', headerName: 'Supplier Name', width: 350, flex: 2 },
                    { field: 'region', headerName: 'Region' },
                    { field: 'category', headerName: 'Category' },
                    {
                      field: 'spend',
                      headerName: 'Spend (£)',
                      width: 125,
                      valueFormatter: params => {
                        if (params.value === null) return ''
                        return params.value.toLocaleString('en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        })
                      },
                    },
                    {
                      field: 'emissions',
                      headerName: 'Emissions (tCO2e)',
                      width: 125,
                      valueFormatter: params => {
                        if (params.value === null) return ''
                        return params.value.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      },
                    },
                    {
                      field: 'emissionsIntensity',
                      headerName: 'Emissions Intensity (kgCO2e/£)',
                      width: 250,
                      valueFormatter: params => {
                        if (params.value === null) return ''
                        return params.value.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      },
                    },
                  ]}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  )
}
const CustomBubbleLegend = ({ maxSpend }: { maxSpend: number }) => {
  const spendLevels = [0.1, 0.25, 0.5, 0.75, 1].map(factor => factor * maxSpend)

  return (
    <Box display="flex" justifyContent="space-between" gap={5}>
      <Box display="flex">
        <Typography variant="subtitle2" fontWeight="bold" mr={1}>
          Emission Intensity
        </Typography>
        <Box>
          <Box
            sx={{
              width: 200,
              height: 20,
              background: 'linear-gradient(to right, #03C0FF33, #03C0FF, #027399)',
              borderRadius: 1,
            }}
          />
          <Box display="flex" justifyContent="space-between" width="100%" fontSize={12}>
            <Typography variant="caption">Low</Typography>
            <Typography variant="caption">High</Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex">
        <Typography variant="subtitle2" fontWeight="bold" mr={1}>
          Spend (£)
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" alignItems="flex-end">
            {spendLevels.map((spend, index) => (
              <Box key={index} display="flex" flexDirection="column" alignItems="center">
                <svg width={32} height={32}>
                  <circle cx="16" cy="16" r={scaleRadius(spend, maxSpend, 1, 13)} fill="#03C0FF" opacity={0.6} />
                </svg>
              </Box>
            ))}
          </Box>
          <Box display="flex" justifyContent="space-between" width="100%" fontSize={12}>
            <Typography variant="caption">Low</Typography>
            <Typography variant="caption">High</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
export default SupplierEmissionsProfile
