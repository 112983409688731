import { useCallback, useEffect, useMemo, useState } from 'react'
import { getUser, signIn } from 'balkerne-core/auth'
import authActions from '../store/auth'
import customActions from '../store/custom'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import siteMap from '../siteMap'
import { ReactComponent as CompanyLogo } from '../images/logo-full.svg'
import BackgroundImage from '../images/login-background.jpg'
import { Box, Button, Paper, Stack, TextField, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import webLogo from '../images/logo_web.png'
import { exchangeCodeForTokens, getTenantInfo, storeTokens } from 'balkerne-core/federatedAuth'
import { useLocation } from 'react-router-dom'

enum SSOEnum {
  UNKNOWN = 'UNKNOWN',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

const Login = () => {
  const params = new URLSearchParams(useLocation().search)
  const code = params.get('code')
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isFederated, setIsFederated] = useState<SSOEnum>(SSOEnum.UNKNOWN)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>('')
  const [success, setSuccess] = useState(true)

  const defaultEmail = useMemo(() => {
    const storedEmail = localStorage.getItem('user')
    return code && storedEmail ? storedEmail : ''
  }, [code])

  useEffect(() => {
    if (!code) return
    const handleFederatedSignIn = async () => {
      setIsLoading(true)
      const tokens = await exchangeCodeForTokens(code)
      if (!tokens) {
        setIsLoading(false)
        setError('Authentication failed')
        setSuccess(false)
        return
      }
      storeTokens(tokens)

      const user = await getUser()
      if (!user) {
        setIsLoading(false)
        setError("Couldn't fetch user information")
        setSuccess(false)
        return
      }
      dispatch(authActions.login({ user }))
      dispatch(customActions.setup(user.organisation.id))

      history.push(siteMap.Portfolio.path)
      setIsLoading(false)
    }
    handleFederatedSignIn()
  }, [code, dispatch, history])

  const forgotPassword = () => {
    history.push({
      pathname: '/ResetPassword',
    })
  }

  const handleGetTenant = useCallback(async () => {
    setIsLoading(true)
    setError(null)
    const data = await getTenantInfo(email)

    if (data) {
      setIsFederated(data.Federation ? SSOEnum.ENABLED : SSOEnum.DISABLED)
      localStorage.setItem('federation', String(data.Federation))
      localStorage.setItem('user', email)
      setSuccess(true)

      if (data.Federation) {
        window.location.href = data.LoginUrl
      }
    } else {
      setIsFederated(SSOEnum.DISABLED)
      setSuccess(false)
      setError('User account not found. Please contact your administrator.')
    }
    setIsLoading(false)
  }, [email])

  const handleSignIn = async () => {
    if (email?.length > 0 && !email.toLowerCase().endsWith('arcusfm.com')) {
      try {
        const { token, user } = await signIn(email, password)
        dispatch(authActions.login({ token, user }))
        dispatch(customActions.setup(user.organisation.id))
        history.push(siteMap.Portfolio.path)
      } catch (err) {
        console.error('First sign in attempt failed:', err)
        try {
          const { token, user } = await signIn(email, password)
          dispatch(authActions.login({ token, user }))
          dispatch(customActions.setup(user.organisation.id))
          history.push(siteMap.Portfolio.path)
        } catch (err2: any) {
          console.error('Second sign in attempt failed:', err2)
          const errorCode = err2?.code ?? undefined
          let errorMessage = err2?.message ?? 'Error occurred, please try again later or contact support'
          if (errorCode === 'UserNotFoundException') {
            errorMessage = 'User account not found. Please contact your administrator.'
          }
          setError(errorMessage)
          setSuccess(false)
        }
      } finally {
        setIsLoading(false)
      }
    }
  }

  const showNotice = () => {
    if (!success) {
      return (
        <div className="alert alert-danger" role="alert">
          {error ?? 'Error occurred, please try again later or contact support'}
        </div>
      )
    }
  }

  const validateForm = useMemo(() => {
    return isFederated === SSOEnum.DISABLED ? email.length > 0 && password.length > 0 : email.length > 0
  }, [isFederated, email, password])

  const handleSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)

    if (isFederated === SSOEnum.ENABLED || isFederated === SSOEnum.UNKNOWN) {
      await handleGetTenant()
    } else {
      await handleSignIn()
    }
  }

  return (
    <Box
      sx={{
        overflow: 'hidden',
        position: 'relative',
      }}>
      <Box
        component={'img'}
        src={BackgroundImage}
        sx={{
          '@keyframes slow-pan': {
            '0%': {
              transform: 'translate(-5%, -5%) scale(1) rotate(-5deg)',
            },
            '100%': {
              transform: 'translate(-15%, -15%) scale(1.15) rotate(15deg)',
            },
          },
          animation: 'slow-pan 30s cubic-bezier(.21,0,.7,1) infinite',
          animationDelay: '-5s',
          animationDirection: 'alternate',
          position: 'absolute',
          top: 0,
          left: 0,
          minWidth: '120vw',
          minHeight: '120vh',
          zIndex: 0,
        }}
      />

      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '100vh',
          width: '100vw',
          backgroundColor: '#000000' + 'AD',
          p: 2,
          zIndex: 1,
          position: 'relative',
        }}>
        <Paper sx={{ width: '100%', maxWidth: 400, p: 4 }}>
          <Stack alignItems="stretch" spacing={2}>
            <Stack alignItems="center" mb={2}>
              <img src={webLogo} width={280} height="auto" />
            </Stack>
            <TextField
              autoFocus
              type="email"
              id="email"
              name="login"
              placeholder="email"
              disabled={code !== null && isLoading}
              defaultValue={defaultEmail}
              onChange={e => setEmail(e.target.value)}
            />
            {isFederated === SSOEnum.DISABLED && (
              <TextField
                type="password"
                id="password"
                name="password"
                placeholder="password"
                onChange={e => setPassword(e.target.value)}
              />
            )}
            <Box />
            <LoadingButton
              loading={isLoading}
              variant="contained"
              size="large"
              fullWidth
              onClick={handleSubmit}
              disabled={!validateForm}>
              Login
            </LoadingButton>
            {isFederated === SSOEnum.DISABLED && (
              <Button
                variant="text"
                fullWidth
                onClick={() => {
                  localStorage.removeItem('user')
                  localStorage.removeItem('federation')
                  setIsFederated(SSOEnum.UNKNOWN)
                }}>
                Login with SSO
              </Button>
            )}
            <Button variant="text" fullWidth onClick={forgotPassword}>
              Forgot Password
            </Button>
            {!isLoading && showNotice()}
          </Stack>
        </Paper>
      </Stack>
    </Box>
  )
}

export default Login
