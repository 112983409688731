import { useCallback, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import SPI from '../components/climateAnalytics/spi'
import HotDays from '../components/climateAnalytics/hotDays'
import Subsidence from '../components/climateAnalytics/subsidence'
import FloodPricing from '../components/climateAnalytics/floodPricing'
import FloodabilityScore from '../components/climateAnalytics/floodabilityScore'
import FloodV2, { useFloodV2Controls } from '../components/climateAnalytics/floodV2'
import PortfolioHeatStress from '../components/climateAnalytics/PortfolioHeatStress'
import PortfolioExtremeWind from '../components/climateAnalytics/PortfolioExtremeWind'
import PortfolioFlood from '../components/climateAnalytics/PortfolioFlood'
import PortfolioWaterRisk from '../components/climateAnalytics/PortfolioWaterRisk'

const hazards = ['heat-stress', 'flood', 'wind', 'water-risk'] as const

const hazardToLabel: Record<Hazard, string> = {
  'heat-stress': 'Heat Stress',
  drought: 'Drought',
  fire: 'Wildfire',
  flood: 'Flood',
  'water-risk': 'Water Stress',
  wind: 'Extreme Wind',
}
type Hazard = (typeof hazards)[number] | 'drought' | 'fire'

const hazardToTitle: Record<Hazard, string> = {
  'heat-stress': 'Heat Stress',
  drought: 'Drought',
  fire: 'Wildfire',
  flood: 'Flood',
  'water-risk': 'Water Stress',
  wind: 'Extreme Wind',
}

const scenarioToLabel: Record<string, string> = {
  rcp85: 'Pessimistic (High Emissions)',
  rcp60: 'Moderate (Moderate-High Emissions)',
  rcp45: 'Moderate (Moderate Emissions)',
  rcp26: 'Optimistic (Low Emissions)',
}

const ClimateAnalytics = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const theme = useTheme()
  const hazard = hazards.includes(history.location.pathname.split('/')?.[2] ?? '')
    ? (history.location.pathname.split('/')?.[2] as Hazard)
    : 'flood'
  const [scenario, setScenario] = useState<string | null>(null)
  const [availableScenarios, _setAvailableScenarios] = useState<string[]>([])

  const setAvailableScenarios = useCallback((scenarios: string[]) => {
    _setAvailableScenarios(scenarios)
    setScenario(scenarios[0])
  }, [])

  const { params, components } = useFloodV2Controls()

  const navigateTo = (hazard: Hazard) => {
    history.push(`${match.path}/${hazard}`)
  }

  // Redirect to the default indicator if the path is exact
  if (match.isExact) {
    navigateTo(hazard)
  }

  console.log('Selected scenario:', scenario)

  return (
    <Stack alignItems={'center'} pb={6}>
      <Stack sx={{ px: 5, pt: 4, maxWidth: theme.breakpoints.values?.['lg'], width: '100%' }} direction="column">
        <Stack pb={3} direction="row" justifyContent="space-between" alignItems="center">
          {/* Title & Optional Back Button */}
          <Stack direction="column">
            <Typography>Climate Impact Analysis</Typography>
            <Typography variant="h5" fontWeight="800" fontSize="36" fontFamily="open sans">
              {hazardToTitle[hazard]}
            </Typography>
          </Stack>

          {/* Spacer */}
          <Box flexGrow={1} />

          {/* Actions */}
          <Stack alignSelf="flex-end" direction="row" spacing={2}>
            {/* {hazard === 'flood-v2' && components} */}
            <FormControl variant="outlined" size="small" sx={{ width: 200 }}>
              <InputLabel id="hazard-label">Hazard</InputLabel>
              <Select
                value={hazard}
                labelId="hazard-label"
                onChange={e => {
                  navigateTo(e.target.value as Hazard)
                }}>
                {hazards
                  .filter(h => !['precipitation', 'wind-speed'].includes(h)) // TEMP
                  .map(hazard => (
                    <MenuItem key={hazard} value={hazard}>
                      {hazardToLabel[hazard]}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {scenario && (
              <FormControl variant="outlined" size="small">
                <InputLabel id="scenario-label">Scenario</InputLabel>
                <Select value={scenario} labelId="scenario-label" onChange={e => setScenario(e.target.value)}>
                  {availableScenarios.map(scenario => (
                    <MenuItem key={scenario} value={scenario}>
                      {scenarioToLabel[scenario]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Stack>
        </Stack>

        {/* Page Contents */}
        <Switch>
          <Route exact path={`${match.path}/heat-stress`}>
            <PortfolioHeatStress setScenarios={setAvailableScenarios} selectedScenario={scenario} />
          </Route>
          <Route exact path={`${match.path}/wind`}>
            <PortfolioExtremeWind setScenarios={setAvailableScenarios} selectedScenario={scenario} />
          </Route>
          <Route exact path={`${match.path}/flood`}>
            <PortfolioFlood setScenarios={setAvailableScenarios} selectedScenario={scenario} />
          </Route>
          <Route exact path={`${match.path}/water-risk`}>
            <PortfolioWaterRisk setScenarios={setAvailableScenarios} selectedScenario={scenario} />
          </Route>

          {/* <Route exact path={`${match.path}/subsidence`}>
            <Subsidence rcp={rcp as any} />
          </Route>
          <Route exact path={`${match.path}/drought`}>
            <SPI rcp={rcp as any} />
          </Route> */}
          {/* <Route exact path={`${match.path}/flood-v2`}>
            <FloodV2 rcp={scenario as any} {...params} />
          </Route>
          <Route exact path={`${match.path}/flood-costs`}>
            <FloodPricing rcp={rcp as any} />
          </Route>
          */}
          {/* <Route exact path={`${match.path}/flood-scores`}>
            <FloodabilityScore rcp={scenario as any} />
          </Route> */}
        </Switch>
      </Stack>
    </Stack>
  )
}

export default ClimateAnalytics
