import { useEffect, useMemo } from 'react'
import api from 'balkerne-core/api'
import ReactGA from 'react-ga'
import { Button, Chip, Hidden, Skeleton, Stack, useTheme } from '@mui/material'
import { Card } from '@mui/material'
import { useLocations } from '../hooks/locations'
import { hotjar } from 'react-hotjar'
import PortfolioMap from '../components/PortfolioMap'
import Box from '@mui/material/Box'
import { Grid, Typography } from '@mui/material'
import { useFormInstances } from '../hooks/forms'
import siteMap from '../siteMap'
import { useHistory } from 'react-router-dom'
import Page from '../components/Page'
import './Portfolio.css' // 🚧 Temporary custom CSS file for backwards compatibility
import { Line } from 'react-chartjs-2'
import { ScriptableContext } from 'chart.js'
import { usePortfolioAlerts } from '../hooks/alerts'
import { FeatureGate } from 'balkerne-core/roles'
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store'

// initialises google analytics tracking
const initialiseAnalytics = () => {
  ReactGA.initialize('UA-146992643-1')
  ReactGA.pageview('/PortfolioDashboard')
  hotjar.initialize(2311893, 6)
}

const getWeekAgo = () => {
  const weekAgo = new Date()
  weekAgo.setDate(weekAgo.getDate() - 7)
  return weekAgo
}

const recentLabels = ['Today', 'Yesterday', '2 days ago', '3 days ago', '4 days ago', '5 days ago', '6 days ago']
const severityPillLabel = {
  1: 'Severe Alert',
  2: 'Warning',
}

const Portfolio = () => {
  const history = useHistory()
  const { groupId } = useSelector((state: RootState) => state.system)
  const { data: locations, isLoading: isLocationsLoading } = useLocations({ group_id: groupId })
  const locationIds = useMemo(() => locations?.map(location => location.id), [locations])
  const { data: allIncidents, isLoading: isIncidentsLoading } = useFormInstances({
    type_id: 2,
    form_state: 'CLOSED',
  })
  const incidents = allIncidents?.filter(incident => locationIds?.includes(incident.property?.id))
  const weekAgo = useMemo(() => getWeekAgo(), [])
  const {
    data: allAlerts,
    isLoading: isAlertsLoading,
    isRefetching: isAlertsRefetching,
  } = usePortfolioAlerts({ last_updated: weekAgo })
  const alerts = allAlerts?.filter(alert => locationIds?.includes(alert.property.id))
  const theme = useTheme()

  const [totalIncidents, dailyIncidentCounts] = useMemo(() => {
    const today = new Date()
    const dailyIncidentCounts = incidents?.reduce(
      (acc, incident) => {
        const date = new Date(incident.started_at)
        const daysAgo = Math.floor((today.getTime() - date.getTime()) / (1000 * 3600 * 24))
        if (daysAgo < 7) {
          acc[daysAgo] += 1
        }
        return acc
      },
      [0, 0, 0, 0, 0, 0, 0],
    )
    const totalIncidents = dailyIncidentCounts.reduce((acc, count) => acc + count, 0)
    return [totalIncidents, dailyIncidentCounts]
  }, [incidents])

  const [activeAlertCount, dailyActiveAlertCounts] = useMemo(() => {
    if (!Array.isArray(alerts)) {
      return [0, [0, 0, 0, 0, 0, 0, 0]]
    }
    const today = new Date()
    const dailyIssuedAlertCounts = alerts.reduce(
      (acc, alert) => {
        const fromDate = alert.deleted_at != undefined ? new Date(alert.deleted_at) : today
        const toDate = new Date(alert.created_at)
        const fromIndex = Math.floor((today.getTime() - fromDate.getTime()) / (1000 * 3600 * 24))
        const toIndex = Math.min(Math.floor((today.getTime() - toDate.getTime()) / (1000 * 3600 * 24)), 6)
        for (let i = fromIndex; i <= toIndex; i++) {
          acc[i] += 1
        }
        return acc
      },
      [0, 0, 0, 0, 0, 0, 0],
    )
    const activeAlertCount = alerts.filter(alert => alert.deleted_at == undefined).length
    // dailyIssuedAlertCounts[0] = activeAlertCount
    return [activeAlertCount, dailyIssuedAlertCounts]
  }, [alerts, isAlertsRefetching])

  // Redirects the page to the chosen user dashboard for a location (redirection inside render method)
  const handleRedirect = async propertyId => {
    history.push(siteMap.Property.getPath(propertyId))
  }

  useEffect(() => {
    initialiseAnalytics()
  }, [])

  const highestActiveSeverity = useMemo(() => {
    let highestSeverity = 4
    if (!Array.isArray(alerts)) {
      return highestSeverity
    }
    for (const alert of alerts) {
      if (alert.deleted_at == null) {
        highestSeverity = Math.min(highestSeverity, alert.severity)
      }
    }
    return highestSeverity
  }, [alerts])

  const cardHeight = '126px'

  return (
    <Page title="Portfolio Dashboard" size={'xl'}>
      <Grid container spacing={3}>
        {/* Property Count */}
        <Grid item xs={12} md>
          <Card sx={{ minHeight: cardHeight }}>
            <Box>
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="subtitle1">Assets</Typography>
                </Stack>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    history.push(siteMap.Organisation.path, { back: true })
                  }}>
                  View
                  <NavigateNextRoundedIcon />
                </Button>
              </Stack>
              {!isLocationsLoading ? (
                <Typography variant="h3">{locations?.length ?? 0}</Typography>
              ) : (
                <Skeleton variant="text" width={100} height={42} />
              )}
            </Box>
          </Card>
        </Grid>

        {/* Recent Alerts */}
        <FeatureGate feature="alerts">
          <Grid item xs={12} md>
            <Card sx={{ minHeight: cardHeight }}>
              <Box>
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row" gap={1}>
                    <Typography variant="subtitle1">Active Threats</Typography>
                    {highestActiveSeverity < 3 && (
                      <Chip
                        label={severityPillLabel[highestActiveSeverity]}
                        size="small"
                        sx={{ backgroundColor: theme.palette.severity[highestActiveSeverity], alignSelf: 'center' }}
                      />
                    )}
                  </Stack>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      history.push(siteMap.PortfolioAlerts.path, { back: true })
                    }}>
                    View
                    <NavigateNextRoundedIcon />
                  </Button>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="end">
                  {!isAlertsLoading ? (
                    <Typography variant="h3" sx={{ width: 'fit-content', mr: 2 }}>
                      {activeAlertCount}
                    </Typography>
                  ) : (
                    <Skeleton variant="text" width={100} height={42} />
                  )}
                  <CardTimeLineChart labels={recentLabels} data={dailyActiveAlertCounts} reverse />
                </Stack>
              </Box>
            </Card>
          </Grid>
        </FeatureGate>
      </Grid>

      <Grid container spacing={3} sx={{ py: 3 }}>
        {/* Portfolio Breakdown */}
        {/* <Grid item lg md={12}>
          <RiskBreakdown setLocationData={setLocationData} handleRedirect={handleRedirect} />
        </Grid> */}
        <Grid item lg md={12}>
          {/* Property Map */}
          <Hidden mdDown>
            <PortfolioMap handleRedirect={handleRedirect} />
          </Hidden>
        </Grid>
      </Grid>
    </Page>
  )
}

type CardTimeLineChartProps = {
  labels: string[]
  data: number[]
  reverse?: boolean
}

const CardTimeLineChart = (props: CardTimeLineChartProps) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        height: '50px',
        width: '100%',
        minWidth: '100px',
        maxWidth: '200px',
      }}>
      <Line
        data={{
          labels: props.labels,
          datasets: [
            {
              data: props.data,
              backgroundColor: (context: ScriptableContext<'line'>) => {
                const ctx = context.chart.ctx
                const gradient = ctx.createLinearGradient(0, 0, 0, 45)
                gradient.addColorStop(0, theme.palette.primary.main)
                gradient.addColorStop(1, '#FFFFFF')
                return gradient
              },
              borderColor: theme.palette.primary.main,
              borderWidth: 1,
              fill: true,
              tension: 0.5,
              pointRadius: 3,
              showLine: true,
              hidden: false,
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            y: {
              border: {
                display: false,
              },
              grid: {
                display: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
              suggestedMax: 1,
              beginAtZero: true,
            },
            x: {
              reverse: props.reverse ?? false,
              grid: {
                display: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
              border: {
                display: false,
              },
            },
          },
        }}
      />
    </Box>
  )
}

export default Portfolio
