import { useEffect, useMemo, useRef, useState } from 'react'
import { useClimatePortfolioData } from '../../../../hooks/analytics'
import {
  Box,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { Layer, Map, MapRef, Source } from 'react-map-gl'
import ConfigProvider from 'balkerne-core/config'
import { Line, Chart } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartData,
} from 'chart.js'
import { FeatureCollection, Point } from '@turf/turf'
import { GradientLegend } from '../../common/Legend'
import { useLocations } from '../../../../hooks/locations'
import LoadingIcon from '../../../LoadingIcon'
import { scoreToColor } from './utils'
import { CardTitle } from '../../common/Card'
import { DataGrid, GridColumns, GridToolbar } from '@mui/x-data-grid'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import siteMap from '../../../../siteMap'
import { useHistory } from 'react-router-dom'

const PortfolioHeatStressBreakdown = ({ scenario }) => {
  const { groupId } = useSelector((state: RootState) => state.system)
  const { data: locations, isLoading: isLocationsLoading } = useLocations({ group_id: groupId })
  const { data, isLoading } = useClimatePortfolioData('heat-stress')
  const history = useHistory()

  const scenarioData = useMemo(() => {
    return data?.['data'][scenario] ?? null
  }, [data, scenario])

  const dataByPropertyId = useMemo(() => {
    return Object.entries(scenarioData?.['hshd'] ?? {}).reduce((acc, [propertyId, data]) => {
      acc[propertyId] = data
      return acc
    }, {})
  }, [scenarioData])

  const dataCols = useMemo(() => {
    return [
      {
        field: 'near-term',
        name: 'Near Term (2030)',
        index: data['indicators']['hshd']['years'].indexOf(2030),
      },
      {
        field: 'mid-term',
        name: 'Mid Term (2050)',
        index: data['indicators']['hshd']['years'].indexOf(2050),
      },
      {
        field: 'long-term',
        name: 'Long Term (2080)',
        index: data['indicators']['hshd']['years'].indexOf(2080),
      },
    ]
  }, [data])

  if (isLoading || isLocationsLoading || !data) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}>
        <LoadingIcon />
      </Box>
    )
  }

  return (
    <Card sx={{ gridArea: 'breakdown' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <CardTitle title="Portfolio Breakdown" subtitle="Heat score for the hottest month" />
      </Stack>
      <Box>
        <DataGrid
          components={{ Toolbar: GridToolbar }}
          sx={{
            height: '470px',
            border: '1px solid transparent',
            '& .MuiDataGrid-footerContainer': {
              border: '1px solid transparent',
            },
          }}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              width: 250,
            },
            {
              field: 'country',
              headerName: 'Country',
              valueGetter: params => {
                return params.row.address.country
              },
              width: 150,
            },
            ...dataCols.map(col => {
              return {
                field: col.field,
                headerName: col.name,
                valueGetter: params => {
                  return dataByPropertyId?.[params.row.id]?.['heat_scores']?.[col.index] ?? null
                },
                renderCell: params => {
                  return (
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        backgroundColor: params.value
                          ? scoreToColor(dataByPropertyId?.[params.row.id]?.['heat_scores']?.[col.index])
                          : 'transparent',
                        borderRadius: '4px',
                        width: 30,
                      }}>
                      {params.value ?? '-'}
                    </Stack>
                  )
                },
                width: 160,
              }
            }),
          ]}
          pageSize={10}
          density="compact"
          getRowId={(row: any) => row.id}
          onRowDoubleClick={params => {
            history.push(siteMap.Property.getPath(params.row.id))
          }}
          rows={locations ?? []}
        />
      </Box>
    </Card>
  )
}

export default PortfolioHeatStressBreakdown
