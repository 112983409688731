import { LegendItem } from 'chart.js'
import Papa from 'papaparse'
import { useEffect, useMemo, useState } from 'react'

export enum INSIGHT_TYPE {
  EMISSIONS_PROFILE = 'emissions-profile',
  CARBON_COST = 'carbon-cost',
  ENERGY_COST = 'energy-cost',
  SUPPLIER_EMISSIONS_PROFILE = 'supplier-emissions-profile',
  SUPPLIER_MARKET_RISK = 'supplier-market-risk',
}
enum DatasetEnum {
  CARBON_PROFILE_2017 = 'CARBON_PROFILE_2017',
  CARBON_PROFILE_2017_REVENUE = 'CARBON_PROFILE_2017_REVENUE',
  CARBON_PROFILE_2024 = 'CARBON_PROFILE_2024',
  CARBON_PROFILE_2024_REVENUE = 'CARBON_PROFILE_2024_REVENUE',
  CARBON_RISK_2017 = 'CARBON_RISK_2017',
  CARBON_RISK_2017_REVENUE = 'CARBON_RISK_2017_REVENUE',
  CARBON_RISK_2024 = 'CARBON_RISK_2024',
  CARBON_RISK_2024_REVENUE = 'CARBON_RISK_2024_REVENUE',
  CARBON_RISK_COMPARISON = 'CARBON_RISK_COMPARISON',
  ENERGY_PROFILE_2017 = 'ENERGY_PROFILE_2017',
  ENERGY_PROFILE_2017_REVENUE = 'ENERGY_PROFILE_2017_REVENUE',
  ENERGY_PROFILE_2024 = 'ENERGY_PROFILE_2024',
  ENERGY_PROFILE_2024_REVENUE = 'ENERGY_PROFILE_2024_REVENUE',
  ENERGY_RISK_2017 = 'ENERGY_RISK_2017',
  ENERGY_RISK_2017_REVENUE = 'ENERGY_RISK_2017_REVENUE',
  ENERGY_RISK_2024 = 'ENERGY_RISK_2024',
  ENERGY_RISK_2024_REVENUE = 'ENERGY_RISK_2024_REVENUE',
  ENERGY_COST_CHANGE = 'ENERGY_COST_CHANGE',
  SUPPLIER_PROFILE_2024 = 'SUPPLIER_PROFILE_2024',
  SUPPLIER_RISK_2024 = 'SUPPLIER_RISK_2024',
  SUPPLIER_RISK_NZ = 'SUPPLIER_RISK_NZ',
}
const datasetFiles: Record<DatasetEnum, string> = {
  [DatasetEnum.CARBON_PROFILE_2017]: './data/mock_27_Feb/Carbon/CARBON_PROFILE_2017.csv',
  [DatasetEnum.CARBON_PROFILE_2017_REVENUE]: './data/mock_27_Feb/Carbon/CARBON_PROFILE_2017_REVENUE.csv',
  [DatasetEnum.CARBON_PROFILE_2024]: './data/mock_27_Feb/Carbon/CARBON_PROFILE_2024.csv',
  [DatasetEnum.CARBON_PROFILE_2024_REVENUE]: './data/mock_27_Feb/Carbon/CARBON_PROFILE_2024_REVENUE.csv',
  [DatasetEnum.CARBON_RISK_2017]: './data/mock_27_Feb/Carbon/CARBON_RISK_2017.csv',
  [DatasetEnum.CARBON_RISK_2017_REVENUE]: './data/mock_27_Feb/Carbon/CARBON_RISK_2017_REVENUE.csv',
  [DatasetEnum.CARBON_RISK_2024]: './data/mock_27_Feb/Carbon/CARBON_RISK_2024.csv',
  [DatasetEnum.CARBON_RISK_2024_REVENUE]: './data/mock_27_Feb/Carbon/CARBON_RISK_2024_REVENUE.csv',
  [DatasetEnum.CARBON_RISK_COMPARISON]: './data/mock_27_Feb/Carbon/CARBON_RISK_COMPARISON.csv',
  [DatasetEnum.ENERGY_PROFILE_2017]: './data/mock_27_Feb/Energy/ENERGY_PROFILE_2017.csv',
  [DatasetEnum.ENERGY_PROFILE_2017_REVENUE]: './data/mock_27_Feb/Energy/ENERGY_PROFILE_2017_REVENUE.csv',
  [DatasetEnum.ENERGY_PROFILE_2024]: './data/mock_27_Feb/Energy/ENERGY_PROFILE_2024.csv',
  [DatasetEnum.ENERGY_PROFILE_2024_REVENUE]: './data/mock_27_Feb/Energy/ENERGY_PROFILE_2024_REVENUE.csv',
  [DatasetEnum.ENERGY_RISK_2017]: './data/mock_27_Feb/Energy/ENERGY_RISK_2017.csv',
  [DatasetEnum.ENERGY_RISK_2017_REVENUE]: './data/mock_27_Feb/Energy/ENERGY_RISK_2017_REVENUE.csv',
  [DatasetEnum.ENERGY_RISK_2024]: './data/mock_27_Feb/Energy/ENERGY_RISK_2024.csv',
  [DatasetEnum.ENERGY_RISK_2024_REVENUE]: './data/mock_27_Feb/Energy/ENERGY_RISK_2024_REVENUE.csv',
  [DatasetEnum.ENERGY_COST_CHANGE]: './data/mock_27_Feb/Energy/ENERGY_COST_CHANGE.csv',
  [DatasetEnum.SUPPLIER_PROFILE_2024]: './data/mock_27_Feb/Market/SUPPLIER_PROFILE_2024.csv',
  [DatasetEnum.SUPPLIER_RISK_2024]: './data/mock_27_Feb/Market/SUPPLIER_RISK_2024.csv',
  [DatasetEnum.SUPPLIER_RISK_NZ]: './data/mock_27_Feb/Market/SUPPLIER_RISK_NZ.csv',
}

const useCSVDataset = (datasets: DatasetEnum[]) => {
  const [data, setData] = useState<Record<DatasetEnum, unknown[]> | null>(null)

  useEffect(() => {
    const parseCSV = async (filePath: string) => {
      return new Promise<any[]>((resolve, reject) => {
        Papa.parse(filePath, {
          download: true,
          dynamicTyping: true,
          header: true,
          skipEmptyLines: true,
          complete: results => {
            if (results.errors.length) {
              reject(results.errors)
            } else {
              resolve(results.data)
            }
          },
          error: err => reject(err),
        })
      })
    }

    const getData = async () => {
      try {
        const parsedData = await Promise.all(
          datasets.map(async dataset => {
            const data = await parseCSV(datasetFiles[dataset])
            return [dataset, data]
          }),
        )

        const result = Object.fromEntries(parsedData)
        setData(result)
      } catch (error) {
        console.error('Error parsing CSV files:', error)
      }
    }
    getData()
  }, [datasetFiles])

  return { data }
}

type Asset = {
  name: string
  itemClass: string
  sector: string
  continent: string
  businessRegion: string
  region: string
}

type AssetCarbonProfile = Asset & {
  scope1Emissions2017: number | null
  scope2Emissions2017: number | null
  totalEmissions2017: number | null
  scope1Emissions2024: number | null
  scope2Emissions2024: number | null
  totalEmissions2024: number | null
  revenue2017: number | null
  revenue2024: number | null
}

export const useCarbonProfile = () => {
  const [loading, setLoading] = useState(true)
  const { data } = useCSVDataset([
    DatasetEnum.CARBON_PROFILE_2017,
    DatasetEnum.CARBON_PROFILE_2024,
    DatasetEnum.CARBON_PROFILE_2017_REVENUE,
    DatasetEnum.CARBON_PROFILE_2024_REVENUE,
  ])

  const initialiseAsset = (data: any) => {
    return {
      name: data.asset_name,
      itemClass: data.item_class,
      sector: data.sector,
      continent: data.continent,
      businessRegion: data.business_region,
      region: data.region,
      scope1Emissions2017: null,
      scope2Emissions2017: null,
      totalEmissions2017: null,
      scope1Emissions2024: null,
      scope2Emissions2024: null,
      totalEmissions2024: null,
      revenue2017: null,
      revenue2024: null,
    }
  }
  const carbonProfile = useMemo(() => {
    if (
      data &&
      data[DatasetEnum.CARBON_PROFILE_2017] &&
      data[DatasetEnum.CARBON_PROFILE_2017_REVENUE] &&
      data[DatasetEnum.CARBON_PROFILE_2024] &&
      data[DatasetEnum.CARBON_PROFILE_2024_REVENUE]
    ) {
      const result = new Map<string, AssetCarbonProfile>()
      data[DatasetEnum.CARBON_PROFILE_2017].forEach((row: any) => {
        if (!result.get(row.asset_name)) {
          result.set(row.asset_name, initialiseAsset(row))
        }
        const asset = result.get(row.asset_name)
        if (asset) {
          asset.scope1Emissions2017 = row.co2_emiss_scope_1
          asset.scope2Emissions2017 = row.co2_emiss_scope_2
          asset.totalEmissions2017 = row.co2_emiss_total
        }
      })
      data[DatasetEnum.CARBON_PROFILE_2017_REVENUE].forEach((row: any) => {
        if (!result.get(row.asset_name)) {
          result.set(row.asset_name, initialiseAsset(row))
        }
        const asset = result.get(row.asset_name)
        if (asset) {
          asset.revenue2017 = row.revenue
        }
      })
      data[DatasetEnum.CARBON_PROFILE_2024].forEach((row: any) => {
        if (!result.get(row.asset_name)) {
          result.set(row.asset_name, initialiseAsset(row))
        }
        const asset = result.get(row.asset_name)
        if (asset) {
          asset.scope1Emissions2024 = row.co2_emiss_scope_1
          asset.scope2Emissions2024 = row.co2_emiss_scope_2
          asset.totalEmissions2024 = row.co2_emiss_total
        }
      })
      data[DatasetEnum.CARBON_PROFILE_2024_REVENUE].forEach((row: any) => {
        if (!result.get(row.asset_name)) {
          result.set(row.asset_name, initialiseAsset(row))
        }
        const asset = result.get(row.asset_name)
        if (asset) {
          asset.revenue2024 = row.revenue
        }
      })
      setLoading(false)
      return Array.from(result.values())
    }
    return []
  }, [data])

  return { carbonProfile, isLoading: loading }
}

export const useEnergyProfile = () => {
  const [loading, setLoading] = useState(true)
  const { data } = useCSVDataset([
    DatasetEnum.ENERGY_PROFILE_2017,
    DatasetEnum.ENERGY_PROFILE_2024,
    DatasetEnum.ENERGY_PROFILE_2017_REVENUE,
    DatasetEnum.ENERGY_PROFILE_2024_REVENUE,
  ])

  const energyProfile = useMemo(() => {
    if (data && data[DatasetEnum.ENERGY_PROFILE_2017] && data[DatasetEnum.ENERGY_PROFILE_2024]) {
      const result: {
        [year: number]: {
          name: string
          sector: string
          continent: string
          businessRegion: string
          energyConsumptionCoal: number
          energyConsumptionGas: number
          energyConsumptionOil: number
          energyConsumptionOther: number
          energyConsumptionElectricityRenewables: number
          energyConsumptionPurchasedRenewables: number
          energyConsumptionElectricityNonRenewables: number
          energyConsumptionTotal: number
        }[]
      } = {
        2017: [],
        2024: [],
      }
      const uniqueBusinessRegions = new Set<string>()
      data[DatasetEnum.ENERGY_PROFILE_2017].forEach((row: any) => {
        result[2017].push({
          name: row.asset_name,
          sector: row.sector,
          continent: row.continent,
          businessRegion: row.business_region,
          energyConsumptionGas: row.energy_consumption_gas,
          energyConsumptionOil: row.energy_consumption_oil,
          energyConsumptionPurchasedRenewables: row.energy_consumption_purchased_renewables,
          energyConsumptionOther: row.energy_consumption_other,
          energyConsumptionElectricityRenewables: row.energy_consumption_electricity_renewable,
          energyConsumptionElectricityNonRenewables: row.energy_consumption_electricity_non_renewable,
          energyConsumptionCoal: row.energy_consumption_coal,
          energyConsumptionTotal: row.energy_consumption_total,
        })
        uniqueBusinessRegions.add(row.business_region)
      })
      data[DatasetEnum.ENERGY_PROFILE_2024].forEach((row: any) => {
        result[2024].push({
          name: row.asset_name,
          sector: row.sector,
          continent: row.continent,
          businessRegion: row.business_region,
          energyConsumptionGas: row.energy_consumption_gas,
          energyConsumptionOil: row.energy_consumption_oil,
          energyConsumptionPurchasedRenewables: row.energy_consumption_purchased_renewables,
          energyConsumptionOther: row.energy_consumption_other,
          energyConsumptionElectricityRenewables: row.energy_consumption_electricity_renewable,
          energyConsumptionElectricityNonRenewables: row.energy_consumption_electricity_non_renewable,
          energyConsumptionCoal: row.energy_consumption_coal,
          energyConsumptionTotal: row.energy_consumption_total,
        })
        uniqueBusinessRegions.add(row.business_region)
      })
      setLoading(false)
      return { data: result, businessRegions: Array.from(uniqueBusinessRegions) }
    }
    return { data: { 2017: [], 2024: [] }, businessRegions: [] }
  }, [data])

  return { energyProfile, isLoading: loading }
}

export const useEnergyCostChange = () => {
  const { data } = useCSVDataset([DatasetEnum.ENERGY_COST_CHANGE])
  const [loading, setLoading] = useState(true)

  const energyCostChange = useMemo(() => {
    if (data && data[DatasetEnum.ENERGY_COST_CHANGE]) {
      const result: {
        [scenario: string]: {
          [year: number]: {
            currentConsumption: number
            targetConsumptionModelScenario: number
            targetConsumption2024Price: number
          }
        }
      } = {}
      data[DatasetEnum.ENERGY_COST_CHANGE].forEach((row: any) => {
        if (!result[row.scenario]) {
          result[row.scenario] = {}
        }
        result[row.scenario][row.year] = {
          currentConsumption: row.Current_consumption,
          targetConsumptionModelScenario: row.Target_consumption_model_scenario_price,
          targetConsumption2024Price: row.Target_consumption_2024price,
        }
      })
      setLoading(false)
      return result
    }
    return {}
  }, [data])

  return { energyCostChange, isLoading: loading }
}

export const useSupplierEmissionsProfile = () => {
  const { data } = useCSVDataset([
    DatasetEnum.SUPPLIER_PROFILE_2024,
    DatasetEnum.SUPPLIER_RISK_2024,
    DatasetEnum.SUPPLIER_RISK_NZ,
  ])
  const [loading, setLoading] = useState(true)

  const supplierProfile = useMemo(() => {
    if (
      data &&
      data[DatasetEnum.SUPPLIER_PROFILE_2024] &&
      data[DatasetEnum.SUPPLIER_RISK_NZ] &&
      data[DatasetEnum.SUPPLIER_RISK_2024]
    ) {
      const suppliers: {
        id: string
        name: string
        region: string
        continent: string
        sector: string
        spendGBP: number
        destinationRegion: string
        supplierRegion: string
        carbonProfile: {
          [year: number]: {
            scope1Emissions: number
            scope2Emissions: number
            totalEmissions: number
          }
        }
      }[] = []
      const supplierRisk: {
        [name: string]: {
          name: string
          sector: string
          continent: string
          destinationRegion: string
          carbonRisk: {
            [scenario: string]: {
              [year: number]: {
                spendGBP: number
                totalEmissions: number
                exposureADJ: number
                exposureGBP: number
              }
            }
          }
        }
      } = {}
      const continents = new Set<string>()

      data[DatasetEnum.SUPPLIER_PROFILE_2024].forEach((row: any) => {
        suppliers.push({
          id: row.supplier_id,
          name: row.supplier_name,
          region: row.supplier_region,
          continent: row.supplier_continent,
          sector: row.sector,
          spendGBP: row.spend_gbp,
          destinationRegion: row.destination_region,
          supplierRegion: row.supplier_region,
          carbonProfile: {
            2024: {
              scope1Emissions: row.co2_emiss_scope_1,
              scope2Emissions: row.co2_emiss_scope_2,
              totalEmissions: row.co2_emiss_total,
            },
          },
        })
        continents.add(row.supplier_continent)
      })
      data[DatasetEnum.SUPPLIER_RISK_2024].forEach((row: any) => {
        if (!supplierRisk[row.supplier_name]) {
          supplierRisk[row.supplier_name] = {
            name: row.supplier_name,
            sector: row.sector,
            continent: row.destination_continent,
            destinationRegion: row.destination_region,
            carbonRisk: {},
          }
        }
      })
      data[DatasetEnum.SUPPLIER_RISK_NZ].forEach((row: any) => {
        const supplier = supplierRisk[row.supplier_name]
        if (!supplier) {
          return
        }
        if (!supplier.carbonRisk[row.scenario]) {
          supplier.carbonRisk[row.scenario] = {}
        }
        supplier.carbonRisk[row.scenario][row.year] = {
          spendGBP: row.spend_gbp,
          totalEmissions: row.emissions_co2e,
          exposureADJ: row.exposure_adj,
          exposureGBP: row.exposure_gbp,
        }
      })

      setLoading(false)
      return { profile: suppliers, risk: supplierRisk, continents: Array.from(continents) }
    }
    return { profile: [], risk: {}, continents: [] }
  }, [data])

  return { supplierProfile, isLoading: loading }
}

export type TransitionRiskAsset = {
  name: string
  itemClass: string
  sector: string
  continent: string
  businessRegion: string
  region: string
  carbonRisk: {
    [scenario: string]: {
      [year: number]: {
        scope1Exposure: number
        scope2Exposure: number
        totalExposure: number
      }
    }
  } | null
  carbonRiskTarget: {
    [scenario: string]: {
      [year: number]: {
        totalExposure17: number
        totalExposure24: number
        totalExposureNZ30: number
      }
    }
  } | null
  carbonRiskWithRevenue: {
    [scenario: string]: {
      [year: number]: {
        scope1Exposure: number
        scope2Exposure: number
        totalExposure: number
        revenue: number
      }
    }
  } | null
  revenue: {
    [year: number]: number
  } | null
}

export const useCarbonRisk = () => {
  const [loading, setLoading] = useState(true)
  const { data } = useCSVDataset([
    DatasetEnum.CARBON_RISK_2017,
    DatasetEnum.CARBON_RISK_2017_REVENUE,
    DatasetEnum.CARBON_RISK_2024,
    DatasetEnum.CARBON_RISK_2024_REVENUE,
    DatasetEnum.CARBON_RISK_COMPARISON,
  ])
  const [result, setResult] = useState<Map<string, TransitionRiskAsset>>(new Map())

  const initialiseAsset = (data: any) => {
    return {
      name: data.asset_name,
      itemClass: data.item_class,
      sector: data.sector,
      continent: data.continent,
      businessRegion: data.business_region,
      region: data.region,
      carbonRisk: null,
      carbonRiskTarget: null,
      carbonRiskWithRevenue: null,
      revenue: null,
    }
  }
  useEffect(() => {
    const assets = new Map<string, TransitionRiskAsset>()
    if (
      data &&
      data[DatasetEnum.CARBON_RISK_2024] &&
      data[DatasetEnum.CARBON_RISK_2024_REVENUE] &&
      data[DatasetEnum.CARBON_RISK_COMPARISON]
    ) {
      data[DatasetEnum.CARBON_RISK_2024].forEach((row: any) => {
        if (!assets.get(row.asset_name)) {
          assets.set(row.asset_name, initialiseAsset(row))
        }
        const asset = assets.get(row.asset_name)
        if (asset) {
          if (!asset.carbonRisk) {
            asset.carbonRisk = {}
          }
          const year = row.year
          const scenario = row.scenario

          if (!asset.carbonRisk[scenario]) {
            asset.carbonRisk[scenario] = {}
          }
          asset.carbonRisk[scenario][year] = {
            scope1Exposure: row.scope_1_exposure,
            scope2Exposure: row.scope_2_exposure,
            totalExposure: row.total_exposure,
          }
        }
      })
      data[DatasetEnum.CARBON_RISK_COMPARISON].forEach((row: any) => {
        if (!assets.get(row.asset_name)) {
          assets.set(row.asset_name, initialiseAsset(row))
        }
        const asset = assets.get(row.asset_name)
        if (asset) {
          if (!asset.carbonRiskTarget) {
            asset.carbonRiskTarget = {}
          }
          const year = row.year
          const scenario = row.scenario

          if (!asset.carbonRiskTarget[scenario]) {
            asset.carbonRiskTarget[scenario] = {}
          }
          asset.carbonRiskTarget[scenario][year] = {
            totalExposure17: row.total_exposure_17,
            totalExposure24: row.total_exposure_24,
            totalExposureNZ30: row.total_exposure_nz30,
          }
        }
      })
      data[DatasetEnum.CARBON_RISK_2024_REVENUE].forEach((row: any) => {
        if (!assets.get(row.asset_name)) {
          assets.set(row.asset_name, initialiseAsset(row))
        }
        const asset = assets.get(row.asset_name)
        if (asset) {
          if (!asset.carbonRiskWithRevenue) {
            asset.carbonRiskWithRevenue = {}
          }
          const year = row.year
          const scenario = row.scenario

          if (!asset.carbonRiskWithRevenue[scenario]) {
            asset.carbonRiskWithRevenue[scenario] = {}
          }
          asset.carbonRiskWithRevenue[scenario][year] = {
            scope1Exposure: row.scope_1_exposure,
            scope2Exposure: row.scope_2_exposure,
            totalExposure: row.total_exposure,
            revenue: row.revenue,
          }
          if (!asset.revenue) {
            asset.revenue = {}
          }
          asset.revenue = { 2024: row.revenue }
        }
      })

      setResult(assets)
      setLoading(false)
    }
  }, [data])

  return { assets: result, isLoading: loading }
}

export const generateUniqueLegendLabels = (chart: any) => {
  const uniqueLabels = new Set<string>()

  return chart.data.datasets
    .filter(dataset => {
      if (!dataset.label) return false
      if (uniqueLabels.has(dataset.label)) {
        return false
      }
      uniqueLabels.add(dataset.label)
      return true
    })
    .map((dataset, index) => ({
      text: dataset.label as string,
      fillStyle: dataset.backgroundColor as string,
      strokeStyle: dataset.backgroundColor as string,
      hidden: !chart.isDatasetVisible(index),
      datasetIndex: index,
    })) as LegendItem[]
}

export const customXScale = {
  id: 'customXScale',
  beforeDatasetsDraw: (chart: any) => {
    const {
      ctx,
      chartArea: { bottom },
      data,
    } = chart
    const hasAddedDatasetSubLabel = {
      '2017': false,
      '2024': false,
    }
    data.datasets.forEach((dataset: any, datasetIndex: number) => {
      chart.getDatasetMeta(datasetIndex).data.forEach((bar: any) => {
        const hasAddedSublabel = {
          '2017': Boolean(hasAddedDatasetSubLabel['2017']),
          '2024': Boolean(hasAddedDatasetSubLabel['2024']),
        }
        if (!hasAddedSublabel[dataset.stack]) {
          ctx.font = '12px sans-serif'
          ctx.fillStyle = 'gray'
          ctx.textAlign = 'center'
          ctx.fillText(dataset.stack, bar.x, bottom + 20)
          hasAddedSublabel[dataset.stack] = true
        }
      })
      hasAddedDatasetSubLabel[dataset.stack] = true
    })
    data.labels.forEach((label: string, index: number) => {
      const metaList = chart.data.datasets.map((_, datasetIndex) => chart.getDatasetMeta(datasetIndex).data[index])

      const xPositions = metaList.map(bar => bar.x).filter(Boolean)
      if (xPositions.length === 0) return

      const centerX = (Math.min(...xPositions) + Math.max(...xPositions)) / 2

      ctx.font = 'bold 12px sans-serif'
      ctx.fillStyle = 'gray'
      ctx.textAlign = 'center'
      ctx.fillText(label, centerX, bottom + 40)
    })
  },
}

export const exportCSV = (filename: string, data: any[]) => {
  const csvData = Papa.unparse(data)
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const pageExportMap = {
  [INSIGHT_TYPE.EMISSIONS_PROFILE]: './data/exports/EMISSIONS_PROFILE_DATA_DOWNLOAD.xlsx',
  [INSIGHT_TYPE.CARBON_COST]: './data/exports/CARBON_COSTS_DATA_DOWNLOAD.xlsx',
  [INSIGHT_TYPE.ENERGY_COST]: './data/exports/ENERGY_DATA_DOWNLOAD.xlsx',
  [INSIGHT_TYPE.SUPPLIER_EMISSIONS_PROFILE]: './data/exports/SUPPLIER_EMISSIONS_PROFILE_2024_DATA.xlsx',
  [INSIGHT_TYPE.SUPPLIER_MARKET_RISK]: './data/exports/MARKET_RISK_DATA_DOWNLOAD.xlsx',
}

export const downloadExcelData = (page: INSIGHT_TYPE) => {
  const url = pageExportMap[page]
  if (!url) {
    console.error('Invalid page type for download:', page)
    return
  }
  const link = document.createElement('a')
  link.href = url
  link.download = url.split('/').pop() || 'download.xlsx'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
