import { Box, Card, FormControl, Grid, InputLabel, MenuItem, Select, Slider, Stack, Typography } from '@mui/material'
import { SCENARIO_TYPE } from '../../pages/TransitionRisks'
import { Bar, Line } from 'react-chartjs-2'
import { useMemo, useState } from 'react'
import { useSupplierEmissionsProfile } from './helpers'
import LoadingIcon from '../LoadingIcon'

const continentColors = {
  Africa: {
    borderColor: '#C81885',
    backgroundColor: '#F0E8EC',
  },
  Asia: {
    borderColor: '#F66D02',
    backgroundColor: '#F1E9E6',
  },
  Europe: {
    borderColor: '#4475C0',
    backgroundColor: '#E1E1ED',
  },
  'North America': {
    borderColor: '#0285B1',
    backgroundColor: '#E1EFF4',
  },
  Oceania: {
    borderColor: '#F4B300',
    backgroundColor: '#F4F0E7',
  },
  'All Regions': {
    borderColor: '#000000',
    backgroundColor: '#000000',
  },
}
const SupplierMarketRisk = ({ scenario }: { scenario: SCENARIO_TYPE }) => {
  const { supplierProfile, isLoading } = useSupplierEmissionsProfile()
  const [yearOptions, setYearOptions] = useState<number[]>([])
  const [selectedYear, setSelectedYear] = useState<number>(2025)
  const [costPassThrough, setCostPassThrough] = useState<number>(100)

  const chartData = useMemo(() => {
    const { risk } = supplierProfile
    const uniqueYears: number[] = []
    Object.values(risk).forEach(supplier => {
      Object.keys(supplier.carbonRisk[scenario]).forEach(year => {
        const parsedYear = parseInt(year)
        if (parsedYear < 2025) return
        if (!uniqueYears.includes(parsedYear)) {
          uniqueYears.push(parsedYear)
        }
      })
    })
    const sortedYears = uniqueYears.sort((a, b) => a - b)
    setYearOptions(sortedYears)

    const years = [2025, 2030, 2035, 2040, 2045, 2050]
    const continents = [...new Set(Object.values(risk).map(supplier => supplier.continent ?? 'Unknown'))]

    // Chart 1 - Opportunity for Cost Avoidance from Scope 3 Emissions Reductions (£s)

    const supplierCostAvoidanceData: any[] = [
      {
        label: 'Current Emissions',
        data: years.map(year =>
          Object.values(risk).reduce(
            (sum, supplier) =>
              sum + ((supplier.carbonRisk?.[scenario]?.[year]?.exposureGBP || 0) * costPassThrough) / 100,
            0,
          ),
        ),
        tension: 0.2,
        borderColor: '#F07300',
      },
      {
        label: 'Target Emissions',
        data: years.map(year =>
          Object.values(risk).reduce(
            (sum, supplier) =>
              sum + ((supplier.carbonRisk?.[scenario]?.[year]?.exposureADJ || 0) * costPassThrough) / 100,
            0,
          ),
        ),
        tension: 0.2,
        borderColor: '#4475C0',
        fill: '-1',
      },
      {
        label: 'Potential Cost Avoidance',
        data: years.map(year =>
          Object.values(risk).reduce(
            (sum, supplier) =>
              sum +
              (((supplier.carbonRisk?.[scenario]?.[year]?.exposureGBP || 0) -
                (supplier.carbonRisk?.[scenario]?.[year]?.exposureADJ || 0)) *
                costPassThrough) /
                100,
            0,
          ),
        ),
        tension: 0.2,
        borderColor: 'gray',
        borderDash: [5, 5],
      },
    ]

    const supplierCostAvoidance = {
      labels: years,
      datasets: supplierCostAvoidanceData,
    }

    // Chart 2
    const supplierCostData: any[] = []
    continents.forEach((continent: string) => {
      const dataPoints = years.map(year =>
        Object.values(risk)
          .filter(supplier => supplier.continent === continent)
          .reduce(
            (sum, supplier) =>
              sum + ((supplier.carbonRisk?.[scenario]?.[year]?.exposureGBP || 0) * costPassThrough) / 100,
            0,
          ),
      )
      supplierCostData.push({
        label: continent,
        data: dataPoints,
        borderColor: continentColors[continent]?.borderColor || 'black',
        backgroundColor: continentColors[continent]?.backgroundColor || 'black',
        tension: 0.2,
      })
    })
    const supplierCostChartData = {
      labels: years,
      datasets: supplierCostData,
    }
    // Select year
    // Cummulative Cost of Supply Chain by selectedYear
    // cost == exposure_gbp
    const sectorCostMap: Record<string, number> = {}

    Object.values(risk).forEach(supplier => {
      const totalExposure = sortedYears.reduce((sum, year) => {
        if (year <= selectedYear) {
          return sum + ((supplier.carbonRisk?.[scenario]?.[year]?.exposureGBP || 0) * costPassThrough) / 100
        } else {
          return sum
        }
      }, 0)

      if (!sectorCostMap[supplier.sector]) {
        sectorCostMap[supplier.sector] = 0
      }
      sectorCostMap[supplier.sector] += totalExposure
    })
    const sortedSectorEntries = Object.entries(sectorCostMap).sort((a, b) => b[1] - a[1])

    const sectorLabels = sortedSectorEntries.map(entry => entry[0])
    const sectorTotalCosts = sortedSectorEntries.map(entry => entry[1])

    const supplierSectorCostChartData = {
      labels: sectorLabels,
      datasets: [
        {
          label: 'Total Cost by Sector',
          data: sectorTotalCosts,
          backgroundColor: '#F07300',
          borderColor: '#F07300',
        },
      ],
    }

    return { supplierCostAvoidance, supplierCostChartData, supplierSectorCostChartData }
  }, [scenario, selectedYear, costPassThrough, supplierProfile])

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <LoadingIcon />
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <Line
                options={{
                  interaction: {
                    mode: 'point',
                    axis: 'x',
                    intersect: false,
                  },
                  plugins: {
                    title: {
                      display: true,
                      text: 'Opportunity for Cost Avoidance from Scope 3 Emissions Reductions (£s)',
                      align: 'start',
                      color: '#53559D',
                      font: {
                        size: 16,
                        weight: 'bold',
                      },
                    },
                    filler: {
                      propagate: true,
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        drawOnChartArea: false,
                      },
                    },
                  },
                  responsive: true,
                }}
                data={chartData.supplierCostAvoidance}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', px: 2, paddingTop: 3, alignContent: 'center' }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 'bold',
                    color: '#53559D',
                  }}>
                  Potential Supplier Carbon Costs (£s)
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: 500,
                      color: '#111927',
                    }}>
                    % of supplier costs passed on
                  </Typography>
                  <Box sx={{ width: 200 }}>
                    <Slider
                      aria-label="Cost Passthrough"
                      value={costPassThrough}
                      valueLabelDisplay="on"
                      onChange={(e, value) => {
                        setCostPassThrough(value as number)
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <hr />
              <Line
                options={{
                  interaction: {
                    mode: 'point',
                    axis: 'x',
                    intersect: false,
                  },
                  plugins: {
                    filler: {
                      propagate: true,
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        drawOnChartArea: false,
                      },
                    },
                  },
                  responsive: true,
                }}
                data={chartData.supplierCostChartData}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <FormControl variant="outlined" size="small" sx={{ width: 200 }}>
                <InputLabel id="year-label">Year</InputLabel>
                <Select
                  value={selectedYear}
                  labelId="year-label"
                  onChange={e => setSelectedYear(e.target.value as number)}>
                  {yearOptions.map(year => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Bar
                options={{
                  indexAxis: 'y' as const,
                  interaction: {
                    mode: 'nearest',
                    axis: 'y',
                    intersect: false,
                  },
                  plugins: {
                    title: {
                      display: true,
                      text: `Cumulative Potential Supplier Cost by Category (£s)`,
                      align: 'start',
                      color: '#53559D',
                      font: {
                        size: 16,
                        weight: 'bold',
                      },
                    },
                  },
                  scales: {
                    y: {
                      grid: {
                        drawOnChartArea: false,
                      },
                    },
                  },
                  responsive: true,
                }}
                data={chartData.supplierSectorCostChartData}
              />
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default SupplierMarketRisk
