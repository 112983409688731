import { Box, Card, Grid } from '@mui/material'
import { SCENARIO_TYPE } from '../../pages/TransitionRisks'
import { Line } from 'react-chartjs-2'
import { useMemo } from 'react'
import { useCarbonRisk } from './helpers'
import LoadingIcon from '../LoadingIcon'

const continentColors = {
  AAACE: {
    borderColor: '#C81885',
    backgroundColor: '#F0E8EC',
  },
  Europe: {
    borderColor: '#4475C0',
    backgroundColor: '#E1E1ED',
  },
  Americas: {
    borderColor: '#0285B1',
    backgroundColor: '#E1EFF4',
  },
  Nordics: {
    borderColor: '#F66D02',
    backgroundColor: '#F1E9E6',
  },
  Farms: {
    borderColor: '#F4B300',
    backgroundColor: '#F4F0E7',
  },
  'All Regions': {
    borderColor: '#000000',
    backgroundColor: '#000000',
  },
}
const CarbonCost = ({ scenario }: { scenario: SCENARIO_TYPE }) => {
  const { assets, isLoading } = useCarbonRisk()
  const chartData = useMemo(() => {
    const years = [2024, 2025, 2030, 2035, 2040, 2045, 2050]

    const scopeChartData = {
      labels: years,
      datasets: [
        {
          label: 'Scope 1 Cost (£)',
          data: years.map(year =>
            Array.from(assets.values()).reduce((sum, asset) => {
              return sum + (asset.carbonRisk?.[scenario]?.[year]?.scope1Exposure || 0)
            }, 0),
          ),
          borderColor: '#F66D02',
          fill: false,
          tension: 0.2,
        },
        {
          label: 'Scope 2 Cost (£)',
          data: years.map(year =>
            Array.from(assets.values()).reduce((sum, asset) => {
              return sum + (asset.carbonRisk?.[scenario]?.[year]?.scope2Exposure || 0)
            }, 0),
          ),
          borderColor: '#F4B300',
          fill: false,
          tension: 0.2,
        },
        {
          label: 'Total Carbon Cost (£)',
          data: years.map(year =>
            Array.from(assets.values()).reduce((sum, asset) => {
              return sum + (asset.carbonRisk?.[scenario]?.[year]?.totalExposure || 0)
            }, 0),
          ),
          borderColor: '#0285B1',
          fill: false,
          tension: 0.2,
        },
      ],
    }
    const businessRegions = [...new Set(Array.from(assets.values()).map(asset => asset.businessRegion))]
    const businessRegionsDataset: any = []
    businessRegions.forEach((businessRegion: any) => {
      if (!businessRegion) return
      const dataPoints = years.map(year =>
        Array.from(assets.values())
          .filter(asset => asset.businessRegion === businessRegion)
          .reduce((sum, asset) => sum + (asset.carbonRisk?.[scenario]?.[year]?.totalExposure || 0), 0),
      )

      businessRegionsDataset.push({
        label: `${businessRegion}`,
        data: dataPoints,
        borderColor: continentColors[businessRegion].borderColor,
        backgroundColor: continentColors[businessRegion].backgroundColor,
        tension: 0.2,
      })
    })
    const continentChartData = {
      labels: years,
      datasets: businessRegionsDataset,
    }

    const dataPoints = [2024, 2025, 2026, 2027, 2028, 2029, 2030].map(year => {
      let currentEmissions = Array.from(assets.values()).reduce((sum, asset) => {
        return sum + (asset.carbonRiskTarget?.[scenario]?.[year]?.totalExposure24 || 0)
      }, 0)

      let targetEmissions = Array.from(assets.values()).reduce((sum, asset) => {
        return sum + (asset.carbonRiskTarget?.[scenario]?.[year]?.totalExposureNZ30 || 0)
      }, 0)

      let baseline2017Emissions = Array.from(assets.values()).reduce((sum, asset) => {
        return sum + (asset.carbonRiskTarget?.[scenario]?.[year]?.totalExposure17 || 0)
      }, 0)
      return {
        year,
        currentEmissions,
        targetEmissions,
        baseline2017Emissions,
      }
    })
    const currentVsTargetEmissionsChartData = {
      labels: [2024, 2025, 2026, 2027, 2028, 2029, 2030],
      datasets: [
        {
          label: 'Current Emissions',
          data: dataPoints.map(dp => dp.currentEmissions),
          borderColor: '#0285B1',
          fill: false,
          tension: 0.2,
        },
        {
          label: 'Target Emissions',
          data: dataPoints.map(dp => dp.targetEmissions),
          borderColor: '#F66D02',
          tension: 0.2,
          fill: '-1',
        },
        // {
        //   label: '2017 Baseline Emissions',
        //   data: dataPoints.map(dp => dp.baseline2017Emissions),
        //   borderColor: 'gray',
        //   borderDash: [5, 5],
        //   fill: false,
        //   tension: 0.2,
        // },
        {
          label: 'Potential Cost Avoidance',
          data: dataPoints.map(dp => dp.currentEmissions - dp.targetEmissions),
          tension: 0.2,
          borderColor: 'gray',
          borderDash: [5, 5],
        },
      ],
    }
    // risk_2024_revenue
    // total exposure & revenue
    const percentageOfRevenue: any = []
    const regionsWithRevenue = [
      ...new Set(
        Array.from(assets.values())
          .filter(asset => asset.revenue)
          .map(asset => asset.businessRegion),
      ),
    ]
    regionsWithRevenue.forEach((businessRegion: any) => {
      if (!businessRegion) return
      const regionRevenue = years.map(year => {
        return Array.from(assets.values())
          .filter(asset => asset.businessRegion === businessRegion)
          .reduce((sum, asset) => {
            return sum + (asset.carbonRiskWithRevenue?.[scenario]?.[year]?.revenue || 0)
          }, 0)
      })
      const totalExposure = years.map(year =>
        Array.from(assets.values())
          .filter(asset => asset.businessRegion === businessRegion)
          .reduce((sum, asset) => sum + (asset.carbonRiskWithRevenue?.[scenario]?.[year]?.totalExposure || 0), 0),
      )

      const dataPoints = totalExposure.map((exposure, index) => {
        return (exposure / regionRevenue[index]) * 100
      })

      percentageOfRevenue.push({
        label: `${businessRegion}`,
        data: dataPoints,
        borderColor: continentColors[businessRegion].borderColor,
        backgroundColor: continentColors[businessRegion].backgroundColor,
        tension: 0.2,
        // fill: true,
      })
    })
    const percentageOfRevenueChartData = {
      labels: years,
      datasets: percentageOfRevenue,
    }
    return { scopeChartData, currentVsTargetEmissionsChartData, continentChartData, percentageOfRevenueChartData }
  }, [assets, scenario])

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <LoadingIcon />
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <Line
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text: 'Potential Carbon Costs Based on Current Emissions (£s)',
                      align: 'start',
                      color: '#53559D',
                      font: {
                        size: 16,
                        weight: 'bold',
                      },
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        drawOnChartArea: false,
                      },
                    },
                  },
                  responsive: true,
                }}
                data={chartData.scopeChartData}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <Line
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text: 'Opportunity for Cost Avoidance From Emissions Reductions (£s)',
                      align: 'start',
                      color: '#53559D',
                      font: {
                        size: 16,
                        weight: 'bold',
                      },
                    },
                    filler: {
                      propagate: true,
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        drawOnChartArea: false,
                      },
                    },
                  },
                  responsive: true,
                }}
                data={chartData.currentVsTargetEmissionsChartData}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <Line
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text: 'Potential Carbon Costs by Region Based on Current Emissions (£s)',
                      align: 'start',
                      color: '#53559D',
                      font: {
                        size: 16,
                        weight: 'bold',
                      },
                    },
                    filler: {
                      propagate: true,
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        drawOnChartArea: false,
                      },
                    },
                  },
                  responsive: true,
                }}
                data={chartData.continentChartData}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <Line
                options={{
                  interaction: {
                    mode: 'point',
                    axis: 'x',
                    intersect: false,
                  },
                  plugins: {
                    title: {
                      display: true,
                      text: 'Potential Carbon Cost Exposure (% of Net Revenue)',
                      align: 'start',
                      color: '#53559D',
                      font: {
                        size: 16,
                        weight: 'bold',
                      },
                    },
                    filler: {
                      propagate: true,
                    },
                  },
                  scales: {
                    x: {
                      grid: {
                        drawOnChartArea: false,
                      },
                    },
                    y: {
                      ticks: {
                        callback: function (value, index, ticks) {
                          return value + '%'
                        },
                      },
                    },
                  },
                  responsive: true,
                }}
                data={chartData.percentageOfRevenueChartData}
              />
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default CarbonCost
