import { useEffect, useMemo, useState } from 'react'
import EmissionsProfile from '../components/TransitionRisk/EmissionsProfile'
import CarbonCost from '../components/TransitionRisk/CarbonCost'
import EnergyCost from '../components/TransitionRisk/EnergyCost'
import SupplierEmissionsProfile from '../components/TransitionRisk/SupplierEmissionsProfile'
import SupplierMarketRisk from '../components/TransitionRisk/SupplierMarketRisk'
import Page from '../components/Page'
import { Button, Grid, List, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material'
import { downloadExcelData, INSIGHT_TYPE, TransitionRiskAsset } from '../components/TransitionRisk/helpers'
import earthenaLogo from '../images/earthena-logo.png'

const INSIGHT_OPTIONS: InsightOption[] = [
  {
    label: 'Emissions Profile',
    value: INSIGHT_TYPE.EMISSIONS_PROFILE,
    title: 'Emissions Profile',
  },
  {
    label: 'Carbon Cost',
    value: INSIGHT_TYPE.CARBON_COST,
    title: 'Carbon Price',
  },
  {
    label: 'Energy Cost',
    value: INSIGHT_TYPE.ENERGY_COST,
    title: 'Energy Cost',
  },
  {
    label: 'Supplier Emissions Profile',
    value: INSIGHT_TYPE.SUPPLIER_EMISSIONS_PROFILE,
    title: 'Supplier Emissions Profile',
  },
  {
    label: 'Supplier Market Risk',
    value: INSIGHT_TYPE.SUPPLIER_MARKET_RISK,
    title: 'Supplier Market Risk',
  },
]

export enum SCENARIO_TYPE {
  NATIONAL_DETERMINED_CONTRIBUTION = 'Nationally Determined Contributions (NDCs)',
  FRAGMENTED_WORLD = 'Fragmented World',
  CURRENT_POLICIES = 'Current Policies',
  BELOW_2_CELSIUS = 'Below 2?C',
  NET_ZERO_2050 = 'Net Zero 2050',
  LOW_DEMAND = 'Low demand',
  DELAYED_TRANSITION = 'Delayed transition',
  SHADOW_PRICE_100 = 'Shadow Price 100',
}

const SCENARIO_OPTIONS = [
  {
    label: 'National Determined Contribution',
    value: SCENARIO_TYPE.NATIONAL_DETERMINED_CONTRIBUTION,
  },
  {
    label: 'Fragmented World',
    value: SCENARIO_TYPE.FRAGMENTED_WORLD,
  },
  {
    label: 'Current Policies',
    value: SCENARIO_TYPE.CURRENT_POLICIES,
  },
  {
    label: 'Below 2°C',
    value: SCENARIO_TYPE.BELOW_2_CELSIUS,
  },
  {
    label: 'Net Zero 2050',
    value: SCENARIO_TYPE.NET_ZERO_2050,
  },
  {
    label: 'Low Demand',
    value: SCENARIO_TYPE.LOW_DEMAND,
  },
  {
    label: 'Delayed Transition',
    value: SCENARIO_TYPE.DELAYED_TRANSITION,
  },
  {
    label: 'Internal Shadow Carbon Price',
    value: SCENARIO_TYPE.SHADOW_PRICE_100,
  },
]

type InsightOption = {
  label: string
  value: INSIGHT_TYPE
  title: string
}

type ScenarioOption = {
  label: string
  value: SCENARIO_TYPE
}

const insightComponents: {
  [key in INSIGHT_TYPE]: React.ComponentType<{
    scenario: SCENARIO_TYPE
  }>
} = {
  [INSIGHT_TYPE.EMISSIONS_PROFILE]: EmissionsProfile,
  [INSIGHT_TYPE.CARBON_COST]: CarbonCost,
  [INSIGHT_TYPE.ENERGY_COST]: EnergyCost,
  [INSIGHT_TYPE.SUPPLIER_EMISSIONS_PROFILE]: SupplierEmissionsProfile,
  [INSIGHT_TYPE.SUPPLIER_MARKET_RISK]: SupplierMarketRisk,
}

const TransitionRisks = () => {
  const [insight, setInsight] = useState<InsightOption>(INSIGHT_OPTIONS[0])
  const [scenario, setScenario] = useState<ScenarioOption>(SCENARIO_OPTIONS[1])

  const InsightComponent = useMemo(
    () => insightComponents[insight.value],
    [insight, scenario],
  )

  return (
    <Page
      title={insight.title}
      size={'xl'}
      PageActions={() => (
        <>
          <Button variant="contained" color="primary" onClick={() => downloadExcelData(insight.value)}>
            Export Data
          </Button>
        </>
      )}>
      <Grid container spacing={3}>
        <Grid item xs={10}>
          {InsightComponent && (
            <InsightComponent scenario={scenario.value} />
          )}
        </Grid>
        <Grid item xs={2}>
          <InsightFilter insight={insight} setInsight={setInsight} />
          <ScenarioFilter scenario={scenario} setScenario={setScenario} insight={insight} />
        </Grid>
        <Grid item xs={10}>
          <Stack direction="column" alignItems="center" spacing={1} sx={{ pt: 3, pb: 2 }}>
            <img
              src={earthenaLogo}
              alt="Earthena logo"
              loading="lazy"
              style={{
                maxWidth: '180px',
                height: 'auto',
                objectFit: 'contain',
              }}
            />
            <Typography variant="body2" color="textSecondary" sx={{ fontSize: '12px' }}>
              Powered by Earthena AI
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ fontSize: '12px' }}>
              Copyright © {new Date().getFullYear()}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Page>
  )
}

const InsightFilter = ({
  insight,
  setInsight,
}: {
  insight: InsightOption
  setInsight: (insight: InsightOption) => void
}) => {
  return (
    <div>
      <Typography variant="h6">Transition Risk Insights</Typography>
      <div>
        <List>
          {INSIGHT_OPTIONS.map(option => (
            <ListItem>
              <ListItemButton
                selected={insight.value === option.value}
                onClick={() => setInsight(option)}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: '#6B6DA7',
                    color: '#FBFBFB',
                  },
                }}>
                <ListItemText primary={option.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  )
}

const ScenarioFilter = ({
  scenario,
  setScenario,
  insight,
}: {
  scenario: ScenarioOption
  insight: InsightOption
  setScenario: (scenario: ScenarioOption) => void
}) => {
  return (
    <div>
      <Typography variant="h6">Emissions Scenario</Typography>
      <div>
        <List>
          {SCENARIO_OPTIONS.map(option => {
            if (insight.value !== INSIGHT_TYPE.CARBON_COST && option.value === SCENARIO_TYPE.SHADOW_PRICE_100)
              return null
            return (
              <ListItem>
                <ListItemButton
                  selected={scenario.value === option.value}
                  onClick={() => setScenario(option)}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: '#E1E1ED',
                    },
                  }}>
                  <ListItemText primary={option.label} />
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </div>
    </div>
  )
}

export default TransitionRisks
